import { Container, Tab, Tabs } from 'react-bootstrap';
import DeyaIndex from './Deya';
import { useLocation } from 'react-router-dom';
import { useContext, useEffect } from 'react';
import { AppContext } from '../../state/State';
import ParametersIndex from './Parameters';
import { useTranslation } from 'react-i18next';
import SensitiveReceiversIndex from './SensitiveReceivers';
import Translations from './Translations';

const DataIndex: React.FC = (props) => {
    const loc = useLocation();
    const context = useContext(AppContext);
    const { t, i18n } = useTranslation();

    useEffect(() => {
        context.dispatch({ type: "SetMainTitleAction", title: t("admin.data") });
    }, [loc.pathname]);

    return (
        <Container fluid className='menu-padding'>
            <Tabs
                defaultActiveKey="deya"
                className="mb-3"
            >
                <Tab eventKey="deya" title={t("admin.deya")}>
                    <DeyaIndex />
                </Tab>
                <Tab eventKey="parameters" title={t("admin.parameters")}>
                    <ParametersIndex />
                </Tab>
                <Tab eventKey="sensitiveReceivers" title={t("admin.sensitiveReceivers")}>
                    <SensitiveReceiversIndex />
                </Tab>
                <Tab eventKey="translations" title={t("admin.translations")}>
                    <Translations />
                </Tab>
            </Tabs>
        </Container >
    );
}

export default DataIndex;
