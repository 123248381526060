import { Row, Col, Form, ButtonGroup, Button, Table, Alert, Modal, Image, Accordion, useAccordionButton, AccordionContext, Card, Overlay, Tooltip, OverlayTrigger, Popover, ButtonToolbar } from "react-bootstrap";
import { Image as EmptyImage, PlusCircle, PencilFill, Trash, CaretDown, CaretUp, QuestionCircle, Layers, PentagonFill, CircleFill, GeoAltFill, Map } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";
import { Formik, FormikProps, useFormikContext } from "formik";
import { Dispatch, SetStateAction, createRef, useContext, useEffect, useRef, useState } from "react";
import { CultivationCategory, ReuseSupplyType, WastewaterTreatmentPlantReuseSupply, WaterBody, WaterBodyType, WaterQualityCategory } from "../../data/Definitions";
import * as yup from 'yup';
import { ConfirmationPopover } from "../../components/ConfirmationPopover";
import Select from 'react-select';
import { wasteTreatmentOptions } from "../../data/Data";

import { fromLonLat, getPointResolution, transformExtent } from "ol/proj";
import { RControl, RFeature, RInteraction, RLayerTile, RLayerVector, RMap, ROSM, RStyle } from "rlayers";
import 'ol/ol.css';
import { Geometry, Polygon, Circle, Point } from "ol/geom";
import WKT from 'ol/format/WKT';
import VectorSource from "ol/source/Vector";
import BaseEvent from "ol/events/Event";
import * as extent from "ol/extent";
import BingMap from "../../components/BingMap";

function calculateSum(array: WastewaterTreatmentPlantReuseSupply[], property: string) {
    if (!array) return 0;
    const total = array.reduce((accumulator, object) => {
        let v = object[property as keyof WastewaterTreatmentPlantReuseSupply];
        if (typeof v === "string") {
            v = parseInt(v);
        }
        if (typeof v === "boolean") {
            v = v ? 1 : 0;
        }
        return accumulator + (v ?? 0);
    }, 0);

    return total;
}

let unique_id = 0;

const ReuseSupplyMap: React.FC<{ formikProps: FormikProps<any>, reuseSupplyType: ReuseSupplyType }> = ({ formikProps, reuseSupplyType }) => {
    const [polygonDrawing, setPolygonDrawing] = useState(false);
    const [pointDrawing, setPointDrawing] = useState(false);
    const [polygonGeometry, setPolygonGeometry] = useState<Geometry | undefined>();
    const [pointGeometry, setPointGeometry] = useState<Geometry | undefined>();
    const mapRef = createRef() as React.RefObject<RMap>;

    return <div style={{ width: "100%", height: "300px" }}>
        <RMap
            className='w-100 h-100'
            initial={{ center: fromLonLat([23.209779819621144, 38.566884483911025]), zoom: 5 }}
            ref={mapRef}
        >
            <RControl.RCustom className="m-2">
                {!(reuseSupplyType === ReuseSupplyType.IndustrialUse || (reuseSupplyType === ReuseSupplyType.UndergroundAquifersEnrichment && formikProps.values.aquifersEnrichmentType !== 0)) && <Button variant="secondary" onClick={() => { !polygonDrawing && setPolygonGeometry(undefined); setPolygonDrawing(!polygonDrawing); }}>
                    <PentagonFill />
                </Button>}
                <Button variant="secondary" onClick={() => { !pointDrawing && setPointGeometry(undefined); setPointDrawing(!pointDrawing); }}>
                    <GeoAltFill />
                </Button>
            </RControl.RCustom>
            <RControl.RLayers className="white-bg m-2 end-0 p-2" element={<Button variant="secondary"><Layers /></Button>}>
                <ROSM properties={{ label: "Map" }} />
                <BingMap properties={{ label: "Satellite" }} accessToken={"AiiKpOh7qCxINCUv1giKXP4j8ycjp0iVmqApb6FmzMlX6erSMM3LzBNr7_hg7wKA"} />
            </RControl.RLayers>
            <RLayerVector
                zIndex={11}
                onChange={(e: BaseEvent) => {
                    let source = e.target as VectorSource<Geometry>;
                    if (!source?.forEachFeatureAtCoordinateDirect) {
                        return;
                    }

                    if (!(polygonDrawing || pointDrawing)) return;

                    let feats = source.getFeatures();
                    if (feats.length > 0) {
                        let geom = feats.filter(x => !x.getProperties().type)[0]?.getGeometry();
                        if (geom) {
                            let wkt = new WKT();
                            let wktGeom = wkt.writeGeometry(geom, { dataProjection: 'EPSG:4326', featureProjection: 'EPSG:3857' });

                            if (polygonDrawing) {
                                formikProps.setFieldValue("shape", wktGeom);
                                setPolygonGeometry(geom);
                                let factor = getPointResolution('EPSG:3857', 1, (geom as Polygon).getFirstCoordinate());
                                let acres = ((geom as Polygon).getArea() * Math.pow(factor, 2)) / 1000;
                                formikProps.setFieldValue("area", acres.toFixed(2));
                            }

                            if (pointDrawing) {
                                setPointGeometry(geom);
                                let ext = geom.getExtent();
                                var lonLatExtent = transformExtent(ext, 'EPSG:3857', 'EPSG:4326');
                                var center = extent.getCenter(lonLatExtent);
                                if (center) {
                                    formikProps.setFieldValue("longitude", center[0]);
                                    formikProps.setFieldValue("latitude", center[1]);
                                }
                            }

                            source.clear();
                            setPolygonDrawing(false);
                            setPointDrawing(false);
                        }
                    }
                }}
            >
                <RFeature
                    key={`Feature${++unique_id}`}
                    geometry={polygonGeometry}
                    properties={{ type: "polygon" }}
                >
                    <RStyle.RStyle>
                        <RStyle.RFill color="rgba(71,86,127, 0.5)"></RStyle.RFill>
                        <RStyle.RStroke width={2} color="#47567f"></RStyle.RStroke>
                    </RStyle.RStyle>
                </RFeature>
                {pointGeometry && <RFeature
                    key={`Feature${++unique_id}`}
                    geometry={pointGeometry}
                    properties={{ type: "point" }}
                >
                    <RStyle.RStyle>
                        <RStyle.RCircle radius={10}>
                            <RStyle.RFill color="rgba(200, 50, 50, 0.5)"></RStyle.RFill>
                            <RStyle.RStroke width={2} color="rgba(200, 50, 50, 1)"></RStyle.RStroke>
                        </RStyle.RCircle>
                    </RStyle.RStyle>
                </RFeature>}
                {polygonDrawing && <RInteraction.RDraw
                    type={"Polygon"}
                />}
                {pointDrawing && <RInteraction.RDraw
                    type={"Point"}
                />}
            </RLayerVector>
        </RMap>
    </div>
}

const ReuseSupplyViewMap: React.FC<{ reuseSupply?: WastewaterTreatmentPlantReuseSupply, setReuseSupply: Dispatch<SetStateAction<WastewaterTreatmentPlantReuseSupply | undefined>> }> = ({ reuseSupply, setReuseSupply }) => {
    const mapRef = createRef() as React.RefObject<RMap>;

    const getInitialPosition = () => {
        if (!reuseSupply) {
            return { center: fromLonLat([23.209779819621144, 38.566884483911025]), zoom: 5 };
        }
        if (reuseSupply.shape) {
            let geom = getFeatureFromString(reuseSupply.shape);
            let ext = geom.getExtent();
            let lonLatExtent = transformExtent(ext, 'EPSG:3857', 'EPSG:4326');
            let lonLatCenter = extent.getCenter(lonLatExtent);
            if (lonLatCenter) {
                return { center: extent.getCenter(ext), zoom: 15 };
            }
        }
        if (reuseSupply.longitude && reuseSupply.latitude) {
            return { center: fromLonLat([reuseSupply.longitude, reuseSupply.latitude]), zoom: 15 };
        }
        return { center: fromLonLat([23.209779819621144, 38.566884483911025]), zoom: 5 };
    }

    const getFeatureFromString = (shape: string) => {
        let wkt = new WKT();
        let shapeGeom = wkt.readGeometry(shape, { dataProjection: 'EPSG:4326', featureProjection: 'EPSG:3857' });
        return shapeGeom;
    }

    if (!reuseSupply) {
        return <></>;
    }

    return <Modal
        size="lg"
        centered
        show
        onHide={() => setReuseSupply(undefined)}
    >
        <Modal.Header closeButton>
            <Modal.Title>
            </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ height: "500px" }}>
            <RMap
                className='w-100 h-100'
                initial={getInitialPosition()}
                ref={mapRef}
            >
                <RControl.RLayers className="white-bg m-2 end-0 p-2" element={<Button variant="secondary"><Layers /></Button>}>
                    <ROSM properties={{ label: "Map" }} />
                    <BingMap properties={{ label: "Satellite" }} accessToken={"AiiKpOh7qCxINCUv1giKXP4j8ycjp0iVmqApb6FmzMlX6erSMM3LzBNr7_hg7wKA"} />
                </RControl.RLayers>
                <RLayerVector
                    zIndex={11}
                >
                    {reuseSupply.shape ? <RFeature
                        key={"polygon"}
                        geometry={getFeatureFromString(reuseSupply.shape)}
                    >
                        <RStyle.RStyle>
                            <RStyle.RFill color="rgba(71,86,127, 0.5)"></RStyle.RFill>
                            <RStyle.RStroke width={2} color="#47567f"></RStyle.RStroke>
                        </RStyle.RStyle>
                    </RFeature>
                        : (reuseSupply.longitude && reuseSupply.latitude) ? <RFeature
                            key={"point"}
                            geometry={new Point(fromLonLat([reuseSupply.longitude, reuseSupply.latitude]))}
                        >
                            <RStyle.RStyle>
                                <RStyle.RCircle radius={10}>
                                    <RStyle.RFill color="rgba(200, 50, 50, 0.5)"></RStyle.RFill>
                                    <RStyle.RStroke width={2} color="rgba(200, 50, 50, 1)"></RStyle.RStroke>
                                </RStyle.RCircle>
                            </RStyle.RStyle>
                        </RFeature>
                            : <></>}
                </RLayerVector>
            </RMap>
        </Modal.Body>
    </Modal>
}

const ReuseSupplyCardHeader = ({ eventKey, supplyType, modalTitle, waterBodies, isUrbanWtp, callback }: { eventKey: string, supplyType: ReuseSupplyType, modalTitle: string, waterBodies: WaterBody[], isUrbanWtp: boolean, callback?: any }) => {
    const { t, i18n } = useTranslation();
    const { handleChange, handleBlur, values, touched, errors, setFieldValue } = useFormikContext();
    const { activeEventKey } = useContext(AccordionContext);
    const [addReuseSupplyModalShow, setAddReuseSupplyModalShow] = useState(false);
    const [sameEntryFound, setSameEntryFound] = useState(false);
    const addReuseSupplyFormRef = useRef<any>(null);

    const reuseSupplyIrrigationSchema = isUrbanWtp ? yup.object().shape({
        supply: yup.number().required(t("wtp.validation.required")).min(0, t("wtp.validation.non_positive")).typeError(t("wtp.validation.type_error")),
        area: yup.number().required(t("wtp.validation.required")).min(0, t("wtp.validation.non_positive")).typeError(t("wtp.validation.type_error")),
        waterBodyId: yup.number().required(t("wtp.validation.required")).oneOf(waterBodies.map(x => x.id), t("wtp.validation.no_select")).typeError(t("wtp.validation.type_error")),
        cultivationCategory: yup.number().required(t("wtp.validation.required")).oneOf([0, 1, 2, 3], t("wtp.validation.no_select")).typeError(t("wtp.validation.type_error")),
        waterQualityCategory: yup.number().required(t("wtp.validation.required")).oneOf([0, 1, 2, 3], t("wtp.validation.no_select")).typeError(t("wtp.validation.type_error")),
        // irrigationMethod: yup.number().required(t("wtp.validation.required")).oneOf([0, 1, 2, 3], t("wtp.validation.no_select")).typeError(t("wtp.validation.type_error")),
    }) : yup.object().shape({
        supply: yup.number().required(t("wtp.validation.required")).min(0, t("wtp.validation.non_positive")).typeError(t("wtp.validation.type_error")),
        area: yup.number().required(t("wtp.validation.required")).min(0, t("wtp.validation.non_positive")).typeError(t("wtp.validation.type_error")),
        waterBodyId: yup.number().required(t("wtp.validation.required")).oneOf(waterBodies.map(x => x.id), t("wtp.validation.no_select")).typeError(t("wtp.validation.type_error")),
        irrigationType: yup.number().required(t("wtp.validation.required")).oneOf([0, 1], t("wtp.validation.no_select")).typeError(t("wtp.validation.type_error")),
        // irrigationMethod: yup.number().required(t("wtp.validation.required")).oneOf([0, 1, 2, 3], t("wtp.validation.no_select")).typeError(t("wtp.validation.type_error")),
    });

    const reuseSupplyUndergroundAquifersEnrichmentSchema = yup.object().shape({
        supply: yup.number().required(t("wtp.validation.required")).min(0, t("wtp.validation.non_positive")).typeError(t("wtp.validation.type_error")),
        area: yup.number().required(t("wtp.validation.required")).min(0, t("wtp.validation.non_positive")).typeError(t("wtp.validation.type_error")),
        waterBodyId: yup.number().required(t("wtp.validation.required")).oneOf(waterBodies.map(x => x.id), t("wtp.validation.no_select")).typeError(t("wtp.validation.type_error")),
        aquifersEnrichmentType: yup.number().required(t("wtp.validation.required")).oneOf([0, 1], t("wtp.validation.no_select")).typeError(t("wtp.validation.type_error")),
    });

    const reuseSupplyUrbanGreenSchema = yup.object().shape({
        supply: yup.number().required(t("wtp.validation.required")).min(0, t("wtp.validation.non_positive")).typeError(t("wtp.validation.type_error")),
        area: yup.number().required(t("wtp.validation.required")).min(0, t("wtp.validation.non_positive")).typeError(t("wtp.validation.type_error")),
        waterBodyId: yup.number().required(t("wtp.validation.required")).oneOf(waterBodies.map(x => x.id), t("wtp.validation.no_select")).typeError(t("wtp.validation.type_error")),
    });

    const reuseSupplyOtherSchema = yup.object().shape({
        supply: yup.number().required(t("wtp.validation.required")).min(0, t("wtp.validation.non_positive")).typeError(t("wtp.validation.type_error")),
        area: yup.number().required(t("wtp.validation.required")).min(0, t("wtp.validation.non_positive")).typeError(t("wtp.validation.type_error")),
        comments: yup.string().required(t("wtp.validation.required")).typeError(t("wtp.validation.type_error")),
    });

    const reuseSupplyIndustrialUseSchema = yup.object().shape({
        supply: yup.number().required(t("wtp.validation.required")).min(0, t("wtp.validation.non_positive")).typeError(t("wtp.validation.type_error")),
        area: yup.number().required(t("wtp.validation.required")).min(0, t("wtp.validation.non_positive")).typeError(t("wtp.validation.type_error")),
        comments: yup.string().required(t("wtp.validation.required")).typeError(t("wtp.validation.type_error")),
    });

    const reuseSupplyUrbanUseSchema = yup.object().shape({
        supply: yup.number().required(t("wtp.validation.required")).min(0, t("wtp.validation.non_positive")).typeError(t("wtp.validation.type_error")),
        area: yup.number().required(t("wtp.validation.required")).min(0, t("wtp.validation.non_positive")).typeError(t("wtp.validation.type_error")),
    });

    const reuseSupplySchema = () => {
        switch (supplyType) {
            case ReuseSupplyType.Irrigation:
                return reuseSupplyIrrigationSchema;
            case ReuseSupplyType.UndergroundAquifersEnrichment:
                return reuseSupplyUndergroundAquifersEnrichmentSchema;
            case ReuseSupplyType.UrbanGreen:
                return reuseSupplyUrbanGreenSchema;
            case ReuseSupplyType.Other:
                return reuseSupplyOtherSchema;
            case ReuseSupplyType.IndustrialUse:
                return reuseSupplyIndustrialUseSchema;
            case ReuseSupplyType.UrbanUse:
                return reuseSupplyUrbanUseSchema;
            default:
                return null;
        }
    }

    const resetSameEntryFoundError = () => {
        setSameEntryFound(false);
    }

    const handleAddReuseSupplySubmit = (val: {
        supply: number,
        area: number,
        waterBodyId: number,
        cultivationCategory: number,
        // irrigationMethod: number,
        irrigationType: number,
        aquifersEnrichmentType: number,
        waterQualityCategory: number,
        cultivationDescription: string,
        comments: string,
        shape: string,
        longitude: number | undefined,
        latitude: number | undefined,
    }) => {
        let cur = (values as any).reuse?.reuseSupplies ?? [];
        let idx = -1;
        if (supplyType === ReuseSupplyType.Irrigation) {
            idx = cur.findIndex((x: WastewaterTreatmentPlantReuseSupply) => x.type === supplyType
                && x.waterBodyId.toString() === val.waterBodyId.toString()
                && (isUrbanWtp ? x.cultivationCategory.toString() === val.cultivationCategory.toString() : x.irrigationType.toString() === val.irrigationType.toString())
                // && x.irrigationMethod.toString() === val.irrigationMethod.toString()
            );
        }

        if (supplyType === ReuseSupplyType.UndergroundAquifersEnrichment || supplyType === ReuseSupplyType.UrbanGreen) {
            idx = cur.findIndex((x: WastewaterTreatmentPlantReuseSupply) => x.type === supplyType
                && x.waterBodyId.toString() === val.waterBodyId.toString()
                && x.aquifersEnrichmentType.toString() === val.aquifersEnrichmentType.toString()
            );
        }

        if (idx > -1) {
            setSameEntryFound(true);
            return;
        }

        let supply = parseFloat(val.supply.toString());
        if (isNaN(supply)) {
            supply = 0;
        }

        let area = parseFloat(val.area.toString());
        if (isNaN(area)) {
            area = 0;
        }

        let industrialUseNonWaterCooling, industrialUseWaterCooling;
        if (supplyType === ReuseSupplyType.IndustrialUse) {
            industrialUseWaterCooling = supply;
            industrialUseNonWaterCooling = area;
            supply += area;
        }

        let newReuseSupply: WastewaterTreatmentPlantReuseSupply = {
            id: 0,
            supply: parseFloat(val.supply.toString()),
            area: parseFloat(val.area.toString()),
            cultivationCategory: parseInt(val.cultivationCategory.toString()),
            // irrigationMethod: parseInt(val.irrigationMethod.toString()),
            irrigationMethod: 0,
            irrigationType: parseInt(val.irrigationType.toString()),
            aquifersEnrichmentType: parseInt(val.aquifersEnrichmentType.toString()),
            waterQualityCategory: parseInt(val.waterQualityCategory.toString()),
            type: supplyType,
            waterBodyId: parseInt(val.waterBodyId.toString()),
            comments: val.comments,
            cultivationDescription: val.cultivationDescription,
            shape: val.shape,
            longitude: val.longitude,
            latitude: val.latitude,
            industrialUseWaterCooling,
            industrialUseNonWaterCooling,
        };
        cur.push(newReuseSupply);
        setFieldValue("reuse.reuseSupplies", cur);
        setAddReuseSupplyModalShow(false);
    };

    const decoratedOnClick = useAccordionButton(
        eventKey,
        () => callback && callback(eventKey),
    );

    const isCurrentEventKey = activeEventKey?.includes(eventKey);

    return (
        <>
            <Card.Header
                onClick={decoratedOnClick}
                style={{ cursor: "pointer" }}
            >
                <Row>
                    <Col md={{ span: 2 }} className="align-self-center">
                        {modalTitle}
                    </Col>
                    <Col md={{ span: 1 }} className="align-self-center text-center">
                        {(calculateSum((values as any).reuse?.reuseSupplies?.filter((x: WastewaterTreatmentPlantReuseSupply) => x.type === supplyType), "supply") ?? 0).toFixed(2)}
                    </Col>
                    <Col md={{ span: 1 }} className="align-self-center text-center">
                        {eventKey === "5" ? "-" : (calculateSum((values as any).reuse?.reuseSupplies?.filter((x: WastewaterTreatmentPlantReuseSupply) => x.type === supplyType), "area") ?? 0).toFixed(2)}
                    </Col>
                    <Col className="text-end">
                        <ButtonGroup className='float-end btn-group-sm mt-1' aria-label="Toolbar">
                            <Button
                                variant="light"
                                onMouseDown={e => e.preventDefault()}
                                className='d-flex align-items-center justify-content-center m-0'
                            >
                                {!isCurrentEventKey ? <CaretDown /> : <CaretUp />}
                            </Button>
                        </ButtonGroup>
                        {isCurrentEventKey && <ButtonGroup className='float-end btn-group-sm me-2' aria-label="Toolbar">
                            <Button variant="outline-primary" className="d-flex align-items-center justify-content-center m-0" onClick={(e) => { e.stopPropagation(); setAddReuseSupplyModalShow(true); }} onMouseDown={e => e.preventDefault()}><PlusCircle className="me-2"></PlusCircle> {t("wtp.button.add_upper")}</Button>
                        </ButtonGroup>}
                    </Col>
                </Row>
            </Card.Header>
            <Modal
                show={addReuseSupplyModalShow}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                onHide={() => setAddReuseSupplyModalShow(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {t("wtp.reuse.supplies.modal.add.header")}&nbsp;({modalTitle})
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Formik
                        initialValues={{
                            supply: 0,
                            area: 0,
                            waterBodyId: 0,
                            cultivationCategory: -1,
                            // irrigationMethod: -1,
                            irrigationType: -1,
                            aquifersEnrichmentType: -1,
                            waterQualityCategory: -1,
                            cultivationDescription: "",
                            comments: "",
                            shape: "",
                            longitude: undefined,
                            latitude: undefined,
                        }}
                        validationSchema={reuseSupplySchema()}
                        onSubmit={(v) => handleAddReuseSupplySubmit(v)}
                    >
                        {props => <Form noValidate ref={addReuseSupplyFormRef} onSubmit={props.handleSubmit}>
                            {supplyType !== ReuseSupplyType.Other && <Form.Group as={Row} className="mb-2">
                                <Form.Label column className="text-end" md={{ span: 4 }}>{t("wtp.reuse.supplies.map")}</Form.Label>
                                <Col md={{ span: 8 }}>
                                    <ReuseSupplyMap formikProps={props} reuseSupplyType={supplyType} />
                                </Col>
                            </Form.Group>}
                            <Form.Group as={Row} className="mb-2">
                                <Form.Label column className="text-end" md={{ span: 4 }}>{(supplyType !== ReuseSupplyType.IndustrialUse ? `${t("wtp.reuse.supplies.supply")} ${t("wtp.reuse.supplies.supply_unit")}` : t("wtp.reuse.supplies.usage_water_cooling")) + " *"}</Form.Label>
                                <Col md={{ span: 8 }}>
                                    <Form.Control
                                        placeholder={`${t("wtp.reuse.supplies.supply")} ${t("wtp.reuse.supplies.supply_unit")}`}
                                        id="supply"
                                        name="supply"
                                        value={props.values.supply}
                                        onChange={props.handleChange}
                                        isInvalid={props.touched.supply && !!props.errors.supply}
                                        isValid={props.touched.supply && !props.errors.supply}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {props.errors.supply}
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-2">
                                <Form.Label column className="text-end" md={{ span: 4 }}>{(supplyType !== ReuseSupplyType.IndustrialUse ? `${t("wtp.reuse.supplies.area")} ${t("wtp.reuse.supplies.area_unit")}` : t("wtp.reuse.supplies.usage_non_water_cooling")) + " *"}</Form.Label>
                                <Col md={{ span: 8 }}>
                                    <Form.Control
                                        placeholder={`${t("wtp.reuse.supplies.area")} ${t("wtp.reuse.supplies.area_unit")}`}
                                        id="area"
                                        name="area"
                                        value={props.values.area}
                                        onChange={props.handleChange}
                                        isInvalid={props.touched.area && !!props.errors.area}
                                        isValid={props.touched.area && !props.errors.area}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {props.errors.area}
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            {(supplyType !== ReuseSupplyType.Other && supplyType !== ReuseSupplyType.UrbanUse && supplyType !== ReuseSupplyType.IndustrialUse) && <Form.Group as={Row} className="mb-2">
                                <Form.Label column className="text-end" md={{ span: 4 }}>{t("wtp.reuse.supplies.ground_water_body") + " *"}</Form.Label>
                                <Col md={{ span: 8 }}>
                                    <Select
                                        noOptionsMessage={() => t("wtp.select.no_options")}
                                        options={[
                                            { value: 0, label: `${t("wtp.reuse.supplies.modal.select_water_body")}`, },
                                        ]
                                            .concat(waterBodies.filter(x => x.type === WaterBodyType.GroundWater).sort((a, b) => (a.name!.toLowerCase() > b.name!.toLowerCase()) ? 1 : ((b.name!.toLowerCase() > a.name!.toLowerCase()) ? -1 : 0)).map(x => { return { value: x.id, label: `${x.code}: ${i18n.language === "en" ? x.nameEn : x.name}` } }))
                                        }
                                        id="waterBodyId"
                                        name="waterBodyId"
                                        onChange={v => { resetSameEntryFoundError(); props.setFieldValue("waterBodyId", v?.value); }}
                                        value={[
                                            { value: 0, label: `${t("wtp.reuse.supplies.modal.select_water_body")}`, },
                                        ]
                                            .concat(waterBodies.filter(x => x.type === WaterBodyType.GroundWater).map(x => { return { value: x.id, label: `${x.code}: ${i18n.language === "en" ? x.nameEn : x.name}` } }))
                                            .find(x => x.value.toString() === props.values["waterBodyId"].toString())
                                        }
                                        className={'react-select ' + ((props.touched.waterBodyId && !props.errors.waterBodyId) ? "is-valid" : (props.touched.waterBodyId && !!props.errors.waterBodyId) ? 'is-invalid' : '')}
                                        classNamePrefix={'react-select'}
                                        menuPosition={"fixed"}
                                        menuPlacement={"auto"}
                                        components={{ IndicatorSeparator: () => null }}
                                        filterOption={(opt, inp) => {
                                            let option: string = opt.label.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "");
                                            let input: string = inp.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "");
                                            return option.indexOf(input) !== -1;
                                        }}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {props.errors.waterBodyId}
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>}
                            {(supplyType === ReuseSupplyType.Irrigation) && <>
                                {isUrbanWtp && <Form.Group as={Row} className="mb-2">
                                    <Form.Label column className="text-end" md={{ span: 4 }}>{t("wtp.reuse.supplies.water_quality_category") + " *"}</Form.Label>
                                    <Col md={{ span: 8 }}>
                                        <Select
                                            noOptionsMessage={() => t("wtp.select.no_options")}
                                            options={[
                                                { value: -1, label: `${t("wtp.reuse.supplies.modal.select_water_quality_category")}`, },
                                                { value: 0, label: `${t("wtp.reuse.water_quality_category.a")}`, },
                                                { value: 1, label: `${t("wtp.reuse.water_quality_category.b")}`, },
                                                { value: 2, label: `${t("wtp.reuse.water_quality_category.c")}`, },
                                                { value: 3, label: `${t("wtp.reuse.water_quality_category.d")}`, },
                                            ]}
                                            id="waterQualityCategory"
                                            name="waterQualityCategory"
                                            onChange={v => { resetSameEntryFoundError(); props.setFieldValue("waterQualityCategory", v?.value); }}
                                            value={[
                                                { value: -1, label: `${t("wtp.reuse.supplies.modal.select_water_quality_category")}`, },
                                                { value: 0, label: `${t("wtp.reuse.water_quality_category.a")}`, },
                                                { value: 1, label: `${t("wtp.reuse.water_quality_category.b")}`, },
                                                { value: 2, label: `${t("wtp.reuse.water_quality_category.c")}`, },
                                                { value: 3, label: `${t("wtp.reuse.water_quality_category.d")}`, },
                                            ].find(x => x.value.toString() === props.values["waterQualityCategory"].toString())}
                                            className={'react-select ' + ((props.touched.waterQualityCategory && !props.errors.waterQualityCategory) ? "is-valid" : (props.touched.waterQualityCategory && !!props.errors.waterQualityCategory) ? 'is-invalid' : '')}
                                            classNamePrefix={'react-select'}
                                            menuPosition={"fixed"}
                                            menuPlacement={"auto"}
                                            components={{ IndicatorSeparator: () => null }}
                                            isSearchable={false}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {props.errors.waterQualityCategory}
                                        </Form.Control.Feedback>
                                    </Col>
                                </Form.Group>}
                                {isUrbanWtp && <Form.Group as={Row} className="mb-2">
                                    <Form.Label column className="text-end" md={{ span: 4 }}>{t("wtp.reuse.supplies.cultivation_category") + " *"}</Form.Label>
                                    <Col md={{ span: 8 }}>
                                        <Select
                                            noOptionsMessage={() => t("wtp.select.no_options")}
                                            options={[
                                                { value: -1, label: `${t("wtp.reuse.supplies.modal.select_cultivation_category")}`, },
                                                { value: 0, label: `${t("wtp.reuse.cultivation_category.a")} - ${t("wtp.reuse.cultivation_category.a.description")}`, },
                                                { value: 1, label: `${t("wtp.reuse.cultivation_category.b")} - ${t("wtp.reuse.cultivation_category.b.description")}`, },
                                                { value: 2, label: `${t("wtp.reuse.cultivation_category.c")} - ${t("wtp.reuse.cultivation_category.c.description")}`, },
                                                { value: 3, label: `${t("wtp.reuse.cultivation_category.d")} - ${t("wtp.reuse.cultivation_category.d.description")}`, },
                                            ]}
                                            id="cultivationCategory"
                                            name="cultivationCategory"
                                            onChange={v => { resetSameEntryFoundError(); props.setFieldValue("cultivationCategory", v?.value); }}
                                            value={[
                                                { value: -1, label: `${t("wtp.reuse.supplies.modal.select_cultivation_category")}`, },
                                                { value: 0, label: `${t("wtp.reuse.cultivation_category.a")}. ${t("wtp.reuse.cultivation_category.a.description")}`, },
                                                { value: 1, label: `${t("wtp.reuse.cultivation_category.b")}. ${t("wtp.reuse.cultivation_category.b.description")}`, },
                                                { value: 2, label: `${t("wtp.reuse.cultivation_category.c")}. ${t("wtp.reuse.cultivation_category.c.description")}`, },
                                                { value: 3, label: `${t("wtp.reuse.cultivation_category.d")}. ${t("wtp.reuse.cultivation_category.d.description")}`, },
                                            ].find(x => x.value.toString() === props.values["cultivationCategory"].toString())}
                                            className={'react-select ' + ((props.touched.cultivationCategory && !props.errors.cultivationCategory) ? "is-valid" : (props.touched.cultivationCategory && !!props.errors.cultivationCategory) ? 'is-invalid' : '')}
                                            classNamePrefix={'react-select'}
                                            menuPosition={"fixed"}
                                            menuPlacement={"auto"}
                                            components={{ IndicatorSeparator: () => null }}
                                            isSearchable={false}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {props.errors.cultivationCategory}
                                        </Form.Control.Feedback>
                                    </Col>
                                </Form.Group>}
                                {!isUrbanWtp && <Form.Group as={Row} className="mb-2">
                                    <Form.Label column className="text-end" md={{ span: 4 }}>{t("wtp.reuse.supplies.irrigation_type") + " *"}</Form.Label>
                                    <Col md={{ span: 8 }}>
                                        <Select
                                            noOptionsMessage={() => t("wtp.select.no_options")}
                                            options={[
                                                { value: -1, label: `${t("wtp.reuse.supplies.modal.select_irrigation_type")}`, },
                                                { value: 0, label: `${t("wtp.reuse.irrigation_type.0.description")}`, },
                                                { value: 1, label: `${t("wtp.reuse.irrigation_type.1.description")}`, },
                                            ]}
                                            id="irrigationType"
                                            name="irrigationType"
                                            onChange={v => { resetSameEntryFoundError(); props.setFieldValue("irrigationType", v?.value); }}
                                            value={[
                                                { value: -1, label: `${t("wtp.reuse.supplies.modal.select_irrigation_type")}`, },
                                                { value: 0, label: `${t("wtp.reuse.irrigation_type.0.description")}`, },
                                                { value: 1, label: `${t("wtp.reuse.irrigation_type.1.description")}`, },
                                            ].find(x => x.value.toString() === props.values["irrigationType"].toString())}
                                            className={'react-select ' + ((props.touched.irrigationType && !props.errors.irrigationType) ? "is-valid" : (props.touched.irrigationType && !!props.errors.irrigationType) ? 'is-invalid' : '')}
                                            classNamePrefix={'react-select'}
                                            menuPosition={"fixed"}
                                            menuPlacement={"auto"}
                                            components={{ IndicatorSeparator: () => null }}
                                            isSearchable={false}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {props.errors.irrigationType}
                                        </Form.Control.Feedback>
                                    </Col>
                                </Form.Group>}
                                {/* <Form.Group as={Row} className="mb-2">
                                    <Form.Label column className="text-end" md={{ span: 4 }}>{t("wtp.reuse.supplies.irrigation_method") + " *"}</Form.Label>
                                    <Col md={{ span: 8 }}>
                                        <Select
                                            noOptionsMessage={() => t("wtp.select.no_options")}
                                            options={[
                                                { value: -1, label: `${t("wtp.reuse.supplies.modal.select_irrigation_method")}`, },
                                                { value: 0, label: `1. ${t("wtp.reuse.irrigation_method.1.description")}`, },
                                                { value: 1, label: `2. ${t("wtp.reuse.irrigation_method.2.description")}`, },
                                                { value: 2, label: `3. ${t("wtp.reuse.irrigation_method.3.description")}`, },
                                                { value: 3, label: `4. ${t("wtp.reuse.irrigation_method.4.description")}`, },
                                            ]}
                                            id="irrigationMethod"
                                            name="irrigationMethod"
                                            onChange={v => { resetSameEntryFoundError(); props.setFieldValue("irrigationMethod", v?.value); }}
                                            value={[
                                                { value: -1, label: `${t("wtp.reuse.supplies.modal.select_irrigation_method")}`, },
                                                { value: 0, label: `1. ${t("wtp.reuse.irrigation_method.1.description")}`, },
                                                { value: 1, label: `2. ${t("wtp.reuse.irrigation_method.2.description")}`, },
                                                { value: 2, label: `3. ${t("wtp.reuse.irrigation_method.3.description")}`, },
                                                { value: 3, label: `4. ${t("wtp.reuse.irrigation_method.4.description")}`, },
                                            ].find(x => x.value.toString() === props.values["irrigationMethod"].toString())}
                                            className={'react-select ' + ((props.touched.irrigationMethod && !props.errors.irrigationMethod) ? "is-valid" : (props.touched.irrigationMethod && !!props.errors.irrigationMethod) ? 'is-invalid' : '')}
                                            classNamePrefix={'react-select'}
                                            menuPosition={"fixed"}
                                            menuPlacement={"auto"}
                                            components={{ IndicatorSeparator: () => null }}
                                            isSearchable={false}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {props.errors.irrigationMethod}
                                        </Form.Control.Feedback>
                                    </Col>
                                </Form.Group> */}
                                <Form.Group as={Row} className="mb-2">
                                    <Form.Label column className="text-end" md={{ span: 4 }}>{t("wtp.reuse.supplies.cultivation_description")}</Form.Label>
                                    <Col md={{ span: 8 }}>
                                        <Form.Control
                                            as="textarea"
                                            rows={3}
                                            placeholder={t("wtp.reuse.supplies.cultivation_description")}
                                            id="cultivationDescription"
                                            name="cultivationDescription"
                                            value={props.values.cultivationDescription}
                                            onChange={props.handleChange}
                                            isInvalid={props.touched.cultivationDescription && !!props.errors.cultivationDescription}
                                            isValid={props.touched.cultivationDescription && !props.errors.cultivationDescription}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {props.errors.cultivationDescription}
                                        </Form.Control.Feedback>
                                    </Col>
                                </Form.Group>
                            </>}
                            {(supplyType === ReuseSupplyType.UndergroundAquifersEnrichment) && <>
                                <Form.Group as={Row} className="mb-2">
                                    <Form.Label column className="text-end" md={{ span: 4 }}>{t("wtp.reuse.supplies.aquifers_enrichment_type") + " *"}</Form.Label>
                                    <Col md={{ span: 8 }}>
                                        <Select
                                            noOptionsMessage={() => t("wtp.select.no_options")}
                                            options={[
                                                { value: -1, label: `${t("wtp.reuse.supplies.modal.select_aquifers_enrichment_type")}`, },
                                                { value: 0, label: `${t("wtp.reuse.aquifers_enrichment_type.0.description")}`, },
                                                { value: 1, label: `${t("wtp.reuse.aquifers_enrichment_type.1.description")}`, },
                                            ]}
                                            id="aquifersEnrichmentType"
                                            name="aquifersEnrichmentType"
                                            onChange={v => { resetSameEntryFoundError(); props.setFieldValue("aquifersEnrichmentType", v?.value); }}
                                            value={[
                                                { value: -1, label: `${t("wtp.reuse.supplies.modal.select_aquifers_enrichment_type")}`, },
                                                { value: 0, label: `${t("wtp.reuse.aquifers_enrichment_type.0.description")}`, },
                                                { value: 1, label: `${t("wtp.reuse.aquifers_enrichment_type.1.description")}`, },
                                            ].find(x => x.value.toString() === props.values["aquifersEnrichmentType"].toString())}
                                            className={'react-select ' + ((props.touched.aquifersEnrichmentType && !props.errors.aquifersEnrichmentType) ? "is-valid" : (props.touched.aquifersEnrichmentType && !!props.errors.aquifersEnrichmentType) ? 'is-invalid' : '')}
                                            classNamePrefix={'react-select'}
                                            menuPosition={"fixed"}
                                            menuPlacement={"auto"}
                                            components={{ IndicatorSeparator: () => null }}
                                            isSearchable={false}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {props.errors.aquifersEnrichmentType}
                                        </Form.Control.Feedback>
                                    </Col>
                                </Form.Group>
                            </>}
                            {(supplyType === ReuseSupplyType.Other || supplyType === ReuseSupplyType.IndustrialUse) && <Form.Group as={Row} className="mb-2">
                                <Form.Label column className="text-end" md={{ span: 4 }}>{(supplyType === ReuseSupplyType.IndustrialUse ? t("wtp.reuse.supplies.description") : t("wtp.reuse.supplies.explanation")) + " *"}</Form.Label>
                                <Col md={{ span: 8 }}>
                                    <Form.Control
                                        as="textarea"
                                        rows={3}
                                        placeholder={supplyType === ReuseSupplyType.IndustrialUse ? t("wtp.reuse.supplies.description") : t("wtp.reuse.supplies.explanation")}
                                        id="comments"
                                        name="comments"
                                        value={props.values.comments}
                                        onChange={props.handleChange}
                                        isInvalid={props.touched.comments && !!props.errors.comments}
                                        isValid={props.touched.comments && !props.errors.comments}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {props.errors.comments}
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>}
                            {sameEntryFound && <Alert variant={'light'} className="m-0">
                                <div className="text-danger">{t("wtp.reuse.supplies.modal.same_entry")}</div>
                            </Alert>}
                        </Form>}
                    </Formik>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => addReuseSupplyFormRef.current?.dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }))}>{t("wtp.button.add")}</Button>
                    <Button variant="secondary" onClick={() => setAddReuseSupplyModalShow(false)}>{t("wtp.button.cancel")}</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

const WtpManageReuse: React.FC<{ waterBodies: WaterBody[] }> = ({ waterBodies }) => {
    const { t, i18n } = useTranslation();
    const { handleChange, handleBlur, values, touched, errors, setFieldValue } = useFormikContext();
    const [selectedReuseSupply, setSelectedReuseSupply] = useState<WastewaterTreatmentPlantReuseSupply>();

    const removeReuseSupply = (reuseSupply: WastewaterTreatmentPlantReuseSupply) => {
        if (!reuseSupply) return;
        let cur = (values as any).reuse.reuseSupplies;
        let idxToRemove = cur.findIndex((x: WastewaterTreatmentPlantReuseSupply) => x === reuseSupply);
        if (idxToRemove === -1) return;
        cur.splice(idxToRemove, 1);
        setFieldValue("reuse.reuseSupplies", cur);
    }

    return (
        <>
            {!(values as any).isVirtual && <Form.Group as={Row} className="mb-2">
                <Col md={{ span: 3, offset: 3 }}>
                    <Form.Check
                        type="checkbox"
                        label={t("wtp.reuse.enable.header")}
                        id="reuseEnabled"
                        name="reuseEnabled"
                        onChange={e => { setFieldValue('reuseEnabled', e.target.checked); if (!(values as any).reuse) setFieldValue('reuse', {}); }}
                        checked={(values as any).reuseEnabled}
                        isInvalid={(touched as any).reuseEnabled && !!(errors as any).reuseEnabled}
                    />
                    <Form.Control.Feedback type="invalid">
                        {(errors as any).reuseEnabled}
                    </Form.Control.Feedback>
                </Col>
            </Form.Group>}
            {(values as any).isVirtual && <>
                <h4>{t("wtp.treatment.waste.header")}</h4>
                <Form.Group as={Row} className="mb-2">
                    <Form.Label column className="text-end" md={{ span: 3 }}>
                        {t("wtp.treatment.method")}
                    </Form.Label>
                    <Col md={{ span: 6 }} className="pe-0">
                        <Select
                            isMulti
                            noOptionsMessage={() => t("wtp.select.no_options")}
                            options={(wasteTreatmentOptions.filter((x, i) => i !== 0).map(x => {
                                return {
                                    value: x.code, label: i18n.language === "en" ? x.nameEn : x.name, isDisabled: (values as any).wasteTreatmentMethod?.split(',').map((x: string) => wasteTreatmentOptions.find(y => y.code === x)).filter((x: any) => x).reduce((accumulator: boolean, currentValue: {
                                        code: string;
                                        name: string;
                                        nameEn: string;
                                        disinfection: boolean;
                                        unique: boolean;
                                    }) => accumulator || currentValue.unique, false) && x.unique
                                }
                            }))}
                            placeholder={i18n.language === "en" ? wasteTreatmentOptions[0].nameEn : wasteTreatmentOptions[0].name}
                            id="wasteTreatmentMethod"
                            name="wasteTreatmentMethod"
                            onChange={v => setFieldValue("wasteTreatmentMethod", Array.from(v?.values()).map(x => x.value).join(','))}
                            value={(values as any).wasteTreatmentMethod?.split(',').map((x: string) => wasteTreatmentOptions.find(y => y.code === x)).filter((x: any) => x).map((x: {
                                code: string;
                                name: string;
                                nameEn: string;
                                disinfection: boolean;
                                unique: boolean;
                            }) => { return { value: x.code, label: i18n.language === "en" ? x.nameEn : x.name, } }) ?? ""}
                            className={'react-select ' + (!(values as any).wasteTreatmentMethod ? 'is-invalid' : '')}
                            classNamePrefix={'react-select'}
                        />
                        <Form.Control.Feedback type="invalid">
                            {(errors as any).wasteTreatmentMethod}
                        </Form.Control.Feedback>
                    </Col>
                </Form.Group>
                {(values as any).wasteTreatmentMethod?.split(',').map((x: string) => wasteTreatmentOptions.find(y => y.code === x)).filter((x: any) => x).reduce((accumulator: boolean, currentValue: {
                    code: string;
                    name: string;
                    nameEn: string;
                    disinfection: boolean;
                    unique: boolean;
                }) => accumulator || currentValue.disinfection, false) && <>
                        <Form.Group as={Row} className="mb-2">
                            <Col md={{ offset: 3 }}>
                                <Form.Check
                                    type="checkbox"
                                    label={t("wtp.treatment.waste.disinfection_chlorination")}
                                    id="disinfectionChlorination"
                                    name="disinfectionChlorination"
                                    checked={(values as any).disinfectionChlorination}
                                    onChange={e => setFieldValue('disinfectionChlorination', e.target.checked)}
                                    isInvalid={(touched as any).disinfectionChlorination && !!(errors as any).disinfectionChlorination}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {(errors as any).disinfectionChlorination}
                                </Form.Control.Feedback>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-2">
                            <Col md={{ offset: 3 }}>
                                <Form.Check
                                    type="checkbox"
                                    label={t("wtp.treatment.waste.disinfection_uv")}
                                    id="disinfectionUltraviolet"
                                    name="disinfectionUltraviolet"
                                    checked={(values as any).disinfectionUltraviolet}
                                    onChange={e => setFieldValue('disinfectionUltraviolet', e.target.checked)}
                                    isInvalid={(touched as any).disinfectionUltraviolet && !!(errors as any).disinfectionUltraviolet}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {(errors as any).disinfectionUltraviolet}
                                </Form.Control.Feedback>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-2">
                            <Col md={{ offset: 3 }}>
                                <Form.Check
                                    type="checkbox"
                                    label={t("wtp.treatment.waste.disinfection_ozone")}
                                    id="disinfectionOzonation"
                                    name="disinfectionOzonation"
                                    checked={(values as any).disinfectionOzonation}
                                    onChange={e => setFieldValue('disinfectionOzonation', e.target.checked)}
                                    isInvalid={(touched as any).disinfectionOzonation && !!(errors as any).disinfectionOzonation}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {(errors as any).disinfectionOzonation}
                                </Form.Control.Feedback>
                            </Col>
                        </Form.Group>
                    </>}
                {(values as any).wasteTreatmentMethod?.includes("3other") &&
                    <Form.Group as={Row} className="mb-2">
                        <Form.Label column className="text-end" md={{ span: 3 }}>
                            {t("wtp.treatment.other_treatment")}
                        </Form.Label>
                        <Col md={{ span: 6 }} className="pe-0">
                            <Form.Control
                                as="textarea"
                                rows={3}
                                id="otherTreatmentDescription"
                                name="otherTreatmentDescription"
                                onBlur={handleChange}
                                defaultValue={(values as any).otherTreatmentDescription || ""}
                                isInvalid={(touched as any).otherTreatmentDescription && !!(errors as any).otherTreatmentDescription}
                            />
                            <Form.Control.Feedback type="invalid">
                                {(errors as any).otherTreatmentDescription}
                            </Form.Control.Feedback>
                        </Col>
                    </Form.Group>}
                <hr></hr>
            </>}
            {(values as any).reuseEnabled && <>
                <h4>{t("wtp.reuse.supplies.header")}</h4>
                {(values as any).isVirtual && <Form.Group as={Row} className="mb-3">
                    <Form.Label column className="text-end" md={{ span: 3 }}>
                        {t("wtp.incoming_load.capacity.wtp_capacity")}
                    </Form.Label>
                    <Col md={{ span: 3 }}>
                        <Form.Control
                            id="capacity"
                            name="capacity"
                            onBlur={handleChange}
                            defaultValue={(values as any).capacity || ""}
                            isInvalid={(touched as any).capacity && !!(errors as any).capacity}
                        />
                        <Form.Control.Feedback type="invalid">
                            {(errors as any).capacity}
                        </Form.Control.Feedback>
                    </Col>
                </Form.Group>}
                <Row className="mb-2" style={{ padding: "0 16px" }}>
                    <Col md={{ span: 2 }} className="align-self-center"></Col>
                    <Col md={{ span: 1 }} className="align-self-center text-center">
                        {`${t("wtp.reuse.supplies.supply")} ${t("wtp.reuse.supplies.supply_unit")}`}
                    </Col>
                    <Col md={{ span: 1 }} className="align-self-center text-center">
                        {`${t("wtp.reuse.supplies.area")} ${t("wtp.reuse.supplies.area_unit")}`}
                    </Col>
                </Row>
                <Accordion defaultActiveKey={[]} alwaysOpen>
                    <Card bg="white" border="light" className="mb-3">
                        <ReuseSupplyCardHeader eventKey="0" supplyType={ReuseSupplyType.Irrigation} modalTitle={t("wtp.reuse.supplies.irrigation")} waterBodies={waterBodies} isUrbanWtp={!(values as any).isVirtual && (values as any).industryId === null} />
                        <Accordion.Collapse eventKey="0">
                            <Card.Body className="p-0 my-2">
                                <Row>
                                    <Col>
                                        <Table striped size="sm" className="mb-0">
                                            <thead>
                                                <tr>
                                                    <th className="col-md-2" style={{ visibility: "hidden", border: "none" }}></th>
                                                    <th className="text-center col-md-1">{`${t("wtp.reuse.supplies.supply")} ${t("wtp.reuse.supplies.supply_unit")}`}</th>
                                                    <th className="text-center col-md-1">{`${t("wtp.reuse.supplies.area")} ${t("wtp.reuse.supplies.area_unit")}`}</th>
                                                    <th className="text-center col-md-auto">{t("wtp.reuse.supplies.ground_water_body")}</th>
                                                    <th className="text-center col-md-auto">A7</th>
                                                    <th className="text-center col-md-auto">NVZ</th>
                                                    {!(values as any).isVirtual && (values as any).industryId === null && <th className="text-center col-md-auto">{t("wtp.reuse.supplies.water_quality_category")}</th>}
                                                    {!(values as any).isVirtual && (values as any).industryId === null && <th className="text-center col-md-auto">{t("wtp.reuse.supplies.cultivation_category")}</th>}
                                                    {/* <th className="text-center col-md-1">{t("wtp.reuse.supplies.irrigation_method")}</th> */}
                                                    {!(!(values as any).isVirtual && (values as any).industryId === null) && <th className="text-center col-md-auto">{t("wtp.reuse.supplies.irrigation_type")}</th>}
                                                    <th className="text-center col-md-auto">{t("wtp.reuse.supplies.cultivation_description")}</th>
                                                    {!(values as any).isVirtual && (values as any).industryId === null && <th className="text-center col-md-auto">{t("wtp.measurements.compliance_reuse.water_quality_check")}</th>}
                                                    <th className="text-center col-md-auto"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {(values as any).reuse?.reuseSupplies?.filter((x: WastewaterTreatmentPlantReuseSupply) => x.type === 0).length > 0 ?
                                                    (values as any).reuse?.reuseSupplies?.filter((x: WastewaterTreatmentPlantReuseSupply) => x.type === 0).map((x: WastewaterTreatmentPlantReuseSupply, i: number) => {
                                                        let wb = waterBodies.find(y => y.id === x.waterBodyId); return <tr key={i}>
                                                            <td style={{ visibility: "hidden", border: "none" }} />
                                                            <td className="text-center align-self-center">{(x.supply ?? 0).toFixed(2)}</td>
                                                            <td className="text-center">{(x.area ?? 0).toFixed(2)}</td>
                                                            <td className="text-center">{wb ? `${wb.code}: ${i18n.language === "en" ? wb.nameEn : wb.name}` : "-"}</td>
                                                            <td className="text-center">{wb ? wb.a7 ? t("yes") : t("no") : "-"}</td>
                                                            <td className="text-center">{wb ? wb.nvz ? t("yes") : t("no") : "-"}</td>
                                                            {!(values as any).isVirtual && (values as any).industryId === null && <td className="text-center">{t(WaterQualityCategory[x.waterQualityCategory])}</td>}
                                                            {!(values as any).isVirtual && (values as any).industryId === null && <td className="text-center">{t(CultivationCategory[x.cultivationCategory])}&nbsp;&nbsp;<OverlayTrigger
                                                                overlay={
                                                                    <Tooltip>
                                                                        {t(CultivationCategory[x.cultivationCategory] + ".description")}
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <QuestionCircle />
                                                            </OverlayTrigger></td>}
                                                            {!(!(values as any).isVirtual && (values as any).industryId === null) && <td className="text-center">{t(`wtp.reuse.irrigation_type.${x.irrigationType}.description`)}</td>}
                                                            <td>{x.cultivationDescription}</td>
                                                            {!(values as any).isVirtual && (values as any).industryId === null && <td className="text-center">{x.suitabilityCheck === true ? "✔️" : x.suitabilityCheck === false ? "❌" : "󠁼➖"}</td>}
                                                            {/* <td className="text-center">{x.irrigationMethod + 1}&nbsp;&nbsp;<OverlayTrigger
                                                                overlay={
                                                                    <Tooltip>
                                                                        {t(`wtp.reuse.irrigation_method.${x.irrigationMethod + 1}.description`)}
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <QuestionCircle />
                                                            </OverlayTrigger></td> */}
                                                            <td>
                                                                <ButtonGroup className='btn-group-sm w-100 justify-content-end' aria-label="Toolbar">
                                                                    {(x.shape || (x.latitude && x.longitude)) && <Button
                                                                        size="sm"
                                                                        variant="outline"
                                                                        style={{ border: "none" }}
                                                                        className="d-flex align-items-center justify-content-center m-0 flex-grow-0"
                                                                        onClick={() => setSelectedReuseSupply(x)}
                                                                    >
                                                                        <Map />
                                                                    </Button>}
                                                                    <ConfirmationPopover
                                                                        id={`unlimited_irrigation-confirmation_popover-${i}`}
                                                                        header={t("wtp.reuse.confirmation.header")}
                                                                        body={t("wtp.reuse.confirmation.body")}
                                                                        okLabel={t("wtp.reuse.confirmation.ok")}
                                                                        onOk={() => removeReuseSupply(x)}
                                                                        cancelLabel={t("wtp.reuse.confirmation.cancel")}
                                                                        onCancel={() => { }}
                                                                    />
                                                                </ButtonGroup>
                                                            </td>
                                                        </tr>
                                                    })
                                                    :
                                                    <tr>
                                                        <td style={{ visibility: "hidden", border: "none" }} />
                                                        <td colSpan={10} className="text-center">
                                                            {t("wtp.table.no_data")}
                                                        </td>
                                                    </tr>}
                                            </tbody>
                                        </Table>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                    {/* <Card bg="white" border="light" className="mb-3">
                        <ReuseSupplyCardHeader eventKey="1" supplyType={ReuseSupplyType.LimitedIrrigation} modalTitle={t("wtp.reuse.supplies.limited_irrigation")} waterBodies={waterBodies} />
                        <Accordion.Collapse eventKey="1">
                            <Card.Body className="p-0 my-2">
                                <Row>
                                    <Col>
                                        <Table striped size="sm" className="mb-0">
                                            <thead>
                                                <tr>
                                                    <th className="col-md-2" style={{ visibility: "hidden", border: "none" }}></th>
                                                    <th className="text-center col-md-1">{t("wtp.reuse.supplies.supply")}</th>
                                                    <th className="text-center col-md-1">{t("wtp.reuse.supplies.area")}</th>
                                                    <th className="text-center col-md-3">{t("wtp.reuse.supplies.ground_water_body")}</th>
                                                    <th className="text-center col-md-1">A7</th>
                                                    <th className="text-center col-md-1">NVZ</th>
                                                    <th className="text-center col-md-1">{t("wtp.reuse.supplies.cultivation_category")}</th>
                                                    <th className="text-center col-md-1">{t("wtp.reuse.supplies.irrigation_method")}</th>
                                                    <th className="text-center col-md-1"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {(values as any).reuse?.reuseSupplies?.filter((x: WastewaterTreatmentPlantReuseSupply) => x.type === 1).length > 0 ?
                                                    (values as any).reuse?.reuseSupplies?.filter((x: WastewaterTreatmentPlantReuseSupply) => x.type === 1).map((x: WastewaterTreatmentPlantReuseSupply, i: number) => {
                                                        let wb = waterBodies.find(y => y.id === x.waterBodyId); return <tr key={i}>
                                                            <td style={{ visibility: "hidden", border: "none" }} />
                                                            <td className="text-center align-self-center">{x.supply}</td>
                                                            <td className="text-center">{x.area}</td>
                                                            <td className="text-center">{wb ? `${wb.code}: ${i18n.language === "en" ? wb.nameEn : wb.name}` : "-"}</td>
                                                            <td className="text-center">{wb ? wb.a7 ? t("yes") : t("no") : "-"}</td>
                                                            <td className="text-center">{wb ? wb.nvz ? t("yes") : t("no") : "-"}</td>
                                                            <td className="text-center">{t(CultivationCategory[x.cultivationCategory])}&nbsp;&nbsp;<OverlayTrigger
                                                                overlay={
                                                                    <Tooltip>
                                                                        {t(CultivationCategory[x.cultivationCategory] + ".description")}
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <QuestionCircle />
                                                            </OverlayTrigger></td>
                                                            <td className="text-center">{x.irrigationMethod + 1}&nbsp;&nbsp;<OverlayTrigger
                                                                overlay={
                                                                    <Tooltip>
                                                                        {t(`wtp.reuse.irrigation_method.${x.irrigationMethod + 1}.description`)}
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <QuestionCircle />
                                                            </OverlayTrigger></td>
                                                            <td>
                                                                <ButtonGroup className='btn-group-sm w-100 justify-content-end' aria-label="Toolbar">
                                                                    <ConfirmationPopover
                                                                        id={`limited_irrigation-confirmation_popover-${i}`}
                                                                        header={t("wtp.reuse.confirmation.header")}
                                                                        body={t("wtp.reuse.confirmation.body")}
                                                                        okLabel={t("wtp.reuse.confirmation.ok")}
                                                                        onOk={() => removeReuseSupply(x)}
                                                                        cancelLabel={t("wtp.reuse.confirmation.cancel")}
                                                                        onCancel={() => { }}
                                                                    />
                                                                </ButtonGroup>
                                                            </td>
                                                        </tr>
                                                    })
                                                    :
                                                    <tr>
                                                        <td style={{ visibility: "hidden", border: "none" }} />
                                                        <td colSpan={10} className="text-center">
                                                            {t("wtp.table.no_data")}
                                                        </td>
                                                    </tr>}
                                            </tbody>
                                        </Table>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card> */}
                    <Card bg="white" border="light" className="mb-3">
                        <ReuseSupplyCardHeader eventKey="2" supplyType={ReuseSupplyType.UndergroundAquifersEnrichment} modalTitle={t("wtp.reuse.supplies.underground_aquifers_enrichment")} waterBodies={waterBodies} isUrbanWtp={!(values as any).isVirtual && (values as any).industryId === null} />
                        <Accordion.Collapse eventKey="2">
                            <Card.Body className="p-0 my-2">
                                <Row>
                                    <Col>
                                        <Table striped size="sm" className="mb-0">
                                            <thead>
                                                <tr>
                                                    <th className="col-md-2" style={{ visibility: "hidden", border: "none" }}></th>
                                                    <th className="text-center col-md-1">{`${t("wtp.reuse.supplies.supply")} ${t("wtp.reuse.supplies.supply_unit")}`}</th>
                                                    <th className="text-center col-md-1">{`${t("wtp.reuse.supplies.area")} ${t("wtp.reuse.supplies.area_unit")}`}</th>
                                                    <th className="text-center col-md-auto">{t("wtp.reuse.supplies.ground_water_body")}</th>
                                                    <th className="text-center col-md-auto">A7</th>
                                                    <th className="text-center col-md-auto">NVZ</th>
                                                    <th className="text-center col-md-auto">{t("wtp.reuse.supplies.aquifers_enrichment_type")}</th>
                                                    <th className="text-center col-md-auto"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {(values as any).reuse?.reuseSupplies?.filter((x: WastewaterTreatmentPlantReuseSupply) => x.type === 2).length > 0 ?
                                                    (values as any).reuse?.reuseSupplies?.filter((x: WastewaterTreatmentPlantReuseSupply) => x.type === 2).map((x: WastewaterTreatmentPlantReuseSupply, i: number) => {
                                                        let wb = waterBodies.find(y => y.id === x.waterBodyId); return <tr key={i}>
                                                            <td style={{ visibility: "hidden", border: "none" }} />
                                                            <td className="text-center align-self-center">{(x.supply ?? 0).toFixed(2)}</td>
                                                            <td className="text-center">{(x.area ?? 0).toFixed(2)}</td>
                                                            <td className="text-center">{wb ? `${wb.code}: ${i18n.language === "en" ? wb.nameEn : wb.name}` : "-"}</td>
                                                            <td className="text-center">{wb ? wb.a7 ? t("yes") : t("no") : "-"}</td>
                                                            <td className="text-center">{wb ? wb.nvz ? t("yes") : t("no") : "-"}</td>
                                                            <td className="text-center">{t(`wtp.reuse.aquifers_enrichment_type.${x.aquifersEnrichmentType}.description`)}</td>
                                                            <td>
                                                                <ButtonGroup className='btn-group-sm w-100 justify-content-end' aria-label="Toolbar">
                                                                    {(x.shape || (x.latitude && x.longitude)) && <Button
                                                                        size="sm"
                                                                        variant="outline"
                                                                        style={{ border: "none" }}
                                                                        className="d-flex align-items-center justify-content-center m-0 flex-grow-0"
                                                                        onClick={() => setSelectedReuseSupply(x)}
                                                                    >
                                                                        <Map />
                                                                    </Button>}
                                                                    <ConfirmationPopover
                                                                        id={`underground_aquifers_enrichment-confirmation_popover-${i}`}
                                                                        header={t("wtp.reuse.confirmation.header")}
                                                                        body={t("wtp.reuse.confirmation.body")}
                                                                        okLabel={t("wtp.reuse.confirmation.ok")}
                                                                        onOk={() => removeReuseSupply(x)}
                                                                        cancelLabel={t("wtp.reuse.confirmation.cancel")}
                                                                        onCancel={() => { }}
                                                                    />
                                                                </ButtonGroup>
                                                            </td>
                                                            <td style={{ visibility: "hidden", border: "none" }} />
                                                        </tr>
                                                    })
                                                    :
                                                    <tr>
                                                        <td style={{ visibility: "hidden", border: "none" }} />
                                                        <td colSpan={7} className="text-center">
                                                            {t("wtp.table.no_data")}
                                                        </td>
                                                    </tr>}
                                            </tbody>
                                        </Table>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                    <Card bg="white" border="light" className="mb-3">
                        <ReuseSupplyCardHeader eventKey="3" supplyType={ReuseSupplyType.UrbanGreen} modalTitle={t("wtp.reuse.supplies.urban_green")} waterBodies={waterBodies} isUrbanWtp={!(values as any).isVirtual && (values as any).industryId === null} />
                        <Accordion.Collapse eventKey="3">
                            <Card.Body className="p-0 my-2">
                                <Row>
                                    <Col>
                                        <Table striped size="sm" className="mb-0">
                                            <thead>
                                                <tr>
                                                    <th className="col-md-2" style={{ visibility: "hidden", border: "none" }}></th>
                                                    <th className="text-center col-md-1">{`${t("wtp.reuse.supplies.supply")} ${t("wtp.reuse.supplies.supply_unit")}`}</th>
                                                    <th className="text-center col-md-1">{`${t("wtp.reuse.supplies.area")} ${t("wtp.reuse.supplies.area_unit")}`}</th>
                                                    <th className="text-center col-md-3">{t("wtp.reuse.supplies.ground_water_body")}</th>
                                                    <th className="text-center col-md-1"></th>
                                                    <th className="col-md-5" style={{ visibility: "hidden", border: "none" }}></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {(values as any).reuse?.reuseSupplies?.filter((x: WastewaterTreatmentPlantReuseSupply) => x.type === 3).length > 0 ?
                                                    (values as any).reuse?.reuseSupplies?.filter((x: WastewaterTreatmentPlantReuseSupply) => x.type === 3).map((x: WastewaterTreatmentPlantReuseSupply, i: number) => {
                                                        let wb = waterBodies.find(y => y.id === x.waterBodyId); return <tr key={i}>
                                                            <td style={{ visibility: "hidden", border: "none" }} />
                                                            <td className="text-center align-self-center">{(x.supply ?? 0).toFixed(2)}</td>
                                                            <td className="text-center">{(x.area ?? 0).toFixed(2)}</td>
                                                            <td className="text-center">{wb ? `${wb.code}: ${i18n.language === "en" ? wb.nameEn : wb.name}` : "-"}</td>
                                                            <td>
                                                                <ButtonGroup className='btn-group-sm w-100 justify-content-end' aria-label="Toolbar">
                                                                    {(x.shape || (x.latitude && x.longitude)) && <Button
                                                                        size="sm"
                                                                        variant="outline"
                                                                        style={{ border: "none" }}
                                                                        className="d-flex align-items-center justify-content-center m-0 flex-grow-0"
                                                                        onClick={() => setSelectedReuseSupply(x)}
                                                                    >
                                                                        <Map />
                                                                    </Button>}
                                                                    <ConfirmationPopover
                                                                        id={`urban_green-confirmation_popover-${i}`}
                                                                        header={t("wtp.reuse.confirmation.header")}
                                                                        body={t("wtp.reuse.confirmation.body")}
                                                                        okLabel={t("wtp.reuse.confirmation.ok")}
                                                                        onOk={() => removeReuseSupply(x)}
                                                                        cancelLabel={t("wtp.reuse.confirmation.cancel")}
                                                                        onCancel={() => { }}
                                                                    />
                                                                </ButtonGroup>
                                                            </td>
                                                            <td style={{ visibility: "hidden", border: "none" }} />
                                                        </tr>
                                                    })
                                                    :
                                                    <tr>
                                                        <td style={{ visibility: "hidden", border: "none" }} />
                                                        <td colSpan={4} className="text-center">
                                                            {t("wtp.table.no_data")}
                                                        </td>
                                                    </tr>}
                                            </tbody>
                                        </Table>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                    <Card bg="white" border="light" className="mb-3">
                        <ReuseSupplyCardHeader eventKey="4" supplyType={ReuseSupplyType.UrbanUse} modalTitle={t("wtp.reuse.supplies.urban_usage")} waterBodies={waterBodies} isUrbanWtp={!(values as any).isVirtual && (values as any).industryId === null} />
                        <Accordion.Collapse eventKey="4">
                            <Card.Body className="p-0 my-2">
                                <Row>
                                    <Col>
                                        <Table striped size="sm" className="mb-0">
                                            <thead>
                                                <tr>
                                                    <th className="col-md-2" style={{ visibility: "hidden", border: "none" }}></th>
                                                    <th className="text-center col-md-1">{`${t("wtp.reuse.supplies.supply")} ${t("wtp.reuse.supplies.supply_unit")}`}</th>
                                                    <th className="text-center col-md-1">{`${t("wtp.reuse.supplies.area")} ${t("wtp.reuse.supplies.area_unit")}`}</th>
                                                    <th className="text-center col-md-1"></th>
                                                    <th className="col-md-7" style={{ visibility: "hidden", border: "none" }}></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {(values as any).reuse?.reuseSupplies?.filter((x: WastewaterTreatmentPlantReuseSupply) => x.type === 4).length > 0 ?
                                                    (values as any).reuse?.reuseSupplies?.filter((x: WastewaterTreatmentPlantReuseSupply) => x.type === 4).map((x: WastewaterTreatmentPlantReuseSupply, i: number) => {
                                                        return <tr key={i}>
                                                            <td style={{ visibility: "hidden", border: "none" }} />
                                                            <td className="text-center align-self-center">{(x.supply ?? 0).toFixed(2)}</td>
                                                            <td className="text-center">{(x.area ?? 0).toFixed(2)}</td>
                                                            <td>
                                                                <ButtonGroup className='btn-group-sm w-100 justify-content-end' aria-label="Toolbar">
                                                                    {(x.shape || (x.latitude && x.longitude)) && <Button
                                                                        size="sm"
                                                                        variant="outline"
                                                                        style={{ border: "none" }}
                                                                        className="d-flex align-items-center justify-content-center m-0 flex-grow-0"
                                                                        onClick={() => setSelectedReuseSupply(x)}
                                                                    >
                                                                        <Map />
                                                                    </Button>}
                                                                    <ConfirmationPopover
                                                                        id={`urban_green-confirmation_popover-${i}`}
                                                                        header={t("wtp.reuse.confirmation.header")}
                                                                        body={t("wtp.reuse.confirmation.body")}
                                                                        okLabel={t("wtp.reuse.confirmation.ok")}
                                                                        onOk={() => removeReuseSupply(x)}
                                                                        cancelLabel={t("wtp.reuse.confirmation.cancel")}
                                                                        onCancel={() => { }}
                                                                    />
                                                                </ButtonGroup>
                                                            </td>
                                                            <td style={{ visibility: "hidden", border: "none" }} />
                                                        </tr>
                                                    })
                                                    :
                                                    <tr>
                                                        <td style={{ visibility: "hidden", border: "none" }} />
                                                        <td colSpan={3} className="text-center">
                                                            {t("wtp.table.no_data")}
                                                        </td>
                                                    </tr>}
                                            </tbody>
                                        </Table>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                    <Card bg="white" border="light" className="mb-3">
                        <ReuseSupplyCardHeader eventKey="5" supplyType={ReuseSupplyType.IndustrialUse} modalTitle={t("wtp.reuse.supplies.industrial_usage")} waterBodies={waterBodies} isUrbanWtp={!(values as any).isVirtual && (values as any).industryId === null} />
                        <Accordion.Collapse eventKey="5">
                            <Card.Body className="p-0 my-2">
                                <Row>
                                    <Col>
                                        <Table striped size="sm" className="mb-0">
                                            <thead>
                                                <tr>
                                                    <th className="col-md-2" style={{ visibility: "hidden", border: "none" }}></th>
                                                    <th className="text-center col-md-1">{`${t("wtp.reuse.supplies.supply")} ${t("wtp.reuse.supplies.supply_unit")}`}</th>
                                                    <th className="text-center col-md-1"></th>
                                                    <th className="text-center col-md-2">{t("wtp.reuse.supplies.usage_water_cooling")}</th>
                                                    <th className="text-center col-md-2">{t("wtp.reuse.supplies.usage_non_water_cooling")}</th>
                                                    <th className="text-center col-md-3">{t("wtp.reuse.supplies.description")}</th>
                                                    <th className="text-center col-md-1"></th>
                                                    <th className="col-md-3" style={{ visibility: "hidden", border: "none" }}></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {(values as any).reuse?.reuseSupplies?.filter((x: WastewaterTreatmentPlantReuseSupply) => x.type === 5).length > 0 ?
                                                    (values as any).reuse?.reuseSupplies?.filter((x: WastewaterTreatmentPlantReuseSupply) => x.type === 5).map((x: WastewaterTreatmentPlantReuseSupply, i: number) => {
                                                        return <tr key={i}>
                                                            <td style={{ visibility: "hidden", border: "none" }} />
                                                            <td className="text-center align-self-center">{(x.supply ?? 0).toFixed(2)}</td>
                                                            <td className="text-center"></td>
                                                            <td className="text-center align-self-center">{(x.industrialUseWaterCooling ?? 0).toFixed(2)}</td>
                                                            <td className="text-center align-self-center">{(x.industrialUseNonWaterCooling ?? 0).toFixed(2)}</td>
                                                            <td className="text-center">{x.comments}</td>
                                                            <td>
                                                                <ButtonGroup className='btn-group-sm w-100 justify-content-end' aria-label="Toolbar">
                                                                    {(x.shape || (x.latitude && x.longitude)) && <Button
                                                                        size="sm"
                                                                        variant="outline"
                                                                        style={{ border: "none" }}
                                                                        className="d-flex align-items-center justify-content-center m-0 flex-grow-0"
                                                                        onClick={() => setSelectedReuseSupply(x)}
                                                                    >
                                                                        <Map />
                                                                    </Button>}
                                                                    <ConfirmationPopover
                                                                        id={`urban_green-confirmation_popover-${i}`}
                                                                        header={t("wtp.reuse.confirmation.header")}
                                                                        body={t("wtp.reuse.confirmation.body")}
                                                                        okLabel={t("wtp.reuse.confirmation.ok")}
                                                                        onOk={() => removeReuseSupply(x)}
                                                                        cancelLabel={t("wtp.reuse.confirmation.cancel")}
                                                                        onCancel={() => { }}
                                                                    />
                                                                </ButtonGroup>
                                                            </td>
                                                            <td style={{ visibility: "hidden", border: "none" }} />
                                                        </tr>
                                                    })
                                                    :
                                                    <tr>
                                                        <td style={{ visibility: "hidden", border: "none" }} />
                                                        <td colSpan={6} className="text-center">
                                                            {t("wtp.table.no_data")}
                                                        </td>
                                                    </tr>}
                                            </tbody>
                                        </Table>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                    <Card bg="white" border="light" className="mb-3">
                        <ReuseSupplyCardHeader eventKey="6" supplyType={ReuseSupplyType.Other} modalTitle={t("wtp.reuse.supplies.other_method")} waterBodies={waterBodies} isUrbanWtp={!(values as any).isVirtual && (values as any).industryId === null} />
                        <Accordion.Collapse eventKey="6">
                            <Card.Body className="p-0 my-2">
                                <Row>
                                    <Col>
                                        <Table striped size="sm" className="mb-0">
                                            <thead>
                                                <tr>
                                                    <th className="col-md-2" style={{ visibility: "hidden", border: "none" }}></th>
                                                    <th className="text-center col-md-1">{`${t("wtp.reuse.supplies.supply")} ${t("wtp.reuse.supplies.supply_unit")}`}</th>
                                                    <th className="text-center col-md-1">{`${t("wtp.reuse.supplies.area")} ${t("wtp.reuse.supplies.area_unit")}`}</th>
                                                    <th className="text-center col-md-3">{t("wtp.reuse.supplies.explanation")}</th>
                                                    <th className="text-center col-md-1"></th>
                                                    <th className="col-md-5" style={{ visibility: "hidden", border: "none" }}></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {(values as any).reuse?.reuseSupplies?.filter((x: WastewaterTreatmentPlantReuseSupply) => x.type === 6).length > 0 ?
                                                    (values as any).reuse?.reuseSupplies?.filter((x: WastewaterTreatmentPlantReuseSupply) => x.type === 6).map((x: WastewaterTreatmentPlantReuseSupply, i: number) =>
                                                        <tr key={i}>
                                                            <td style={{ visibility: "hidden", border: "none" }} />
                                                            <td className="text-center align-self-center">{(x.supply ?? 0).toFixed(2)}</td>
                                                            <td className="text-center">{(x.area ?? 0).toFixed(2)}</td>
                                                            <td className="text-center">{x.comments}</td>
                                                            <td>
                                                                <ButtonGroup className='btn-group-sm w-100 justify-content-end' aria-label="Toolbar">
                                                                    <ConfirmationPopover
                                                                        id={`other_method-confirmation_popover-${i}`}
                                                                        header={t("wtp.reuse.confirmation.header")}
                                                                        body={t("wtp.reuse.confirmation.body")}
                                                                        okLabel={t("wtp.reuse.confirmation.ok")}
                                                                        onOk={() => removeReuseSupply(x)}
                                                                        cancelLabel={t("wtp.reuse.confirmation.cancel")}
                                                                        onCancel={() => { }}
                                                                    />
                                                                </ButtonGroup>
                                                            </td>
                                                            <td style={{ visibility: "hidden", border: "none" }} />
                                                        </tr>
                                                    )
                                                    :
                                                    <tr>
                                                        <td style={{ visibility: "hidden", border: "none" }} />
                                                        <td colSpan={4} className="text-center">
                                                            {t("wtp.table.no_data")}
                                                        </td>
                                                    </tr>}
                                            </tbody>
                                        </Table>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
                <strong>
                    <Row className="my-2" style={{ padding: "0 16px" }}>
                        <Col md={{ span: 2 }} className="align-self-center text-end">
                            {t("wtp.reuse.supplies.total")}
                        </Col>
                        <Col md={{ span: 1 }} className="align-self-center text-center">
                            {(calculateSum((values as any).reuse?.reuseSupplies, "supply") ?? 0).toFixed(2)}
                        </Col>
                        <Col md={{ span: 1 }} className="align-self-center text-center">
                            {(calculateSum((values as any).reuse?.reuseSupplies, "area") ?? 0).toFixed(2)}
                        </Col>
                    </Row>
                </strong>
                <hr></hr>
                <h4>{t("wtp.reuse.additional.header")}</h4>
                <Form.Group as={Row} className="mb-2">
                    <Form.Label column className="text-end" md={{ span: 3 }}>{t("wtp.reuse.additional.limited_capacity_islandic_area")}</Form.Label>
                    <Col md={{ span: 6 }} className="align-self-center">
                        <Form.Check
                            type="checkbox"
                            id="reuse.limitedCapacityIslandicArea"
                            name="reuse.limitedCapacityIslandicArea"
                            onChange={e => setFieldValue('reuse.limitedCapacityIslandicArea', e.target.checked)}
                            checked={(values as any).reuse?.limitedCapacityIslandicArea}
                            isInvalid={(touched as any).reuse?.limitedCapacityIslandicArea && !!(errors as any).reuse?.limitedCapacityIslandicArea}
                        />
                        <Form.Control.Feedback type="invalid">
                            {(errors as any).reuse?.limitedCapacityIslandicArea}
                        </Form.Control.Feedback>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-2">
                    <Form.Label column className="text-end" md={{ span: 3 }}>{t("wtp.reuse.additional.land_irrigation")}</Form.Label>
                    <Col md={{ span: 6 }} className="align-self-center">
                        <Form.Check
                            type="checkbox"
                            id="reuse.landIrrigation"
                            name="reuse.landIrrigation"
                            onChange={e => setFieldValue('reuse.landIrrigation', e.target.checked)}
                            checked={(values as any).reuse?.landIrrigation}
                            isInvalid={(touched as any).reuse?.landIrrigation && !!(errors as any).reuse?.landIrrigation}
                        />
                        <Form.Control.Feedback type="invalid">
                            {(errors as any).reuse?.landIrrigation}
                        </Form.Control.Feedback>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-2">
                    <Form.Label column className="text-end" md={{ span: 3 }}>{t("wtp.reuse.additional.aerosolization_hazard")}</Form.Label>
                    <Col md={{ span: 6 }} className="align-self-center">
                        <Form.Check
                            type="checkbox"
                            id="reuse.aerosolizationHazard"
                            name="reuse.aerosolizationHazard"
                            onChange={e => setFieldValue('reuse.aerosolizationHazard', e.target.checked)}
                            checked={(values as any).reuse?.aerosolizationHazard}
                            isInvalid={(touched as any).reuse?.aerosolizationHazard && !!(errors as any).reuse?.aerosolizationHazard}
                        />
                        <Form.Control.Feedback type="invalid">
                            {(errors as any).reuse?.aerosolizationHazard}
                        </Form.Control.Feedback>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-2">
                    <Form.Label column className="text-end" md={{ span: 3 }}>{t("wtp.reuse.additional.reuse_comments")}</Form.Label>
                    <Col md={{ span: 6 }} className="pe-0">
                        <Form.Control
                            as="textarea"
                            rows={3}
                            id="reuse.reuseWaterComments"
                            name="reuse.reuseWaterComments"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={(values as any).reuse?.reuseWaterComments || ""}
                            isInvalid={(touched as any).reuse?.reuseWaterComments && !!(errors as any).reuse?.reuseWaterComments}
                        />
                        <Form.Control.Feedback type="invalid">
                            {(errors as any).reuse?.reuseWaterComments}
                        </Form.Control.Feedback>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-2">
                    <Form.Label column className="text-end" md={{ span: 3 }}>{t("wtp.reuse.additional.hazards_comments")}</Form.Label>
                    <Col md={{ span: 6 }} className="pe-0">
                        <Form.Control
                            as="textarea"
                            rows={3}
                            id="reuse.hazardComments"
                            name="reuse.hazardComments"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={(values as any).reuse?.hazardComments || ""}
                            isInvalid={(touched as any).reuse?.hazardComments && !!(errors as any).reuse?.hazardComments}
                        />
                        <Form.Control.Feedback type="invalid">
                            {(errors as any).reuse?.hazardComments}
                        </Form.Control.Feedback>
                    </Col>
                </Form.Group>
            </>}
            <ReuseSupplyViewMap setReuseSupply={setSelectedReuseSupply} reuseSupply={selectedReuseSupply} />
        </>
    )
}

export default WtpManageReuse;
