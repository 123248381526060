import logo from '../assets/images/logo.png'
import { useTranslation, Trans } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../state/State';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import useFetch from 'use-http';
import { UserInfo } from '../data/User';

const Login: React.FC<{ onLogin: () => void }> = ({ onLogin }) => {
    const { t, i18n } = useTranslation();
    const loc = useLocation();
    const context = useContext(AppContext);
    const [errorLoggingIn, setErrorLoggingIn] = useState(false);
    const { cache } = useFetch();
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        context.dispatch({ type: "SetMainTitleAction", title: "login" });
    }, [loc.pathname]);

    useEffect(() => {
        checkCookie();
    }, [])

    const checkCookie = async () => {
        let res = await fetch('/api/account/cookie');
        if (res.status !== 200 || res.redirected) {
            cache.delete("url:/api/wastewatertreatmentplant||method:GET||body:");
            context.dispatch({
                type: "LOGOUT",
            });
        }
    }

    const signIn = async (e: React.FormEvent) => {
        if (!e) return;
        e.preventDefault();
        setErrorLoggingIn(false);
        let formData = new FormData(e.target as HTMLFormElement);
        let data = await fetch('/api/account/signin', {
            method: 'POST',
            body: formData,
        });
        if (data.status === 200) {
            onLogin();
            let userInfo = await data.json();
            if ((userInfo as UserInfo).roles.findIndex(x => x.toLowerCase() === "sysadmin") !== -1) {
                await fetch('/manager/login/auth', { redirect: 'manual', });
            }
            context.dispatch({
                type: "LOGIN",
                payload: {
                    userInfo: userInfo,
                }
            });
            if (location.state?.returnUrl) {
                navigate(location.state.returnUrl);
            }
        } else {
            setErrorLoggingIn(true);
        }
    }

    const signOut = async () => {
        await fetch('/api/account/signout');
        cache.delete("url:/api/wastewatertreatmentplant||method:GET||body:");
        context.dispatch({
            type: "LOGOUT",
        });
    }

    return (
        context.state.loggedIn ?
            <div className="form-signin-container">
                <div className="form-signin w-100 m-auto text-center">
                    {/* <img className="mb-4" src={logo} alt={t("Wastewater Treatment Plants Monitoring Database")} width="93" height="93" /> */}
                    <div>
                        {t("You are currently signed in as")}
                    </div>
                    <div className='mb-3'>
                        <strong>{context.state.userInfo?.email}</strong>
                    </div>
                    <button className="w-100 btn btn-lg btn-primary" type="button" onClick={signOut}>{t("Sign out")}</button>
                </div>
            </div> :
            <div className="form-signin-container">
                <form className="form-signin w-100 m-auto text-center" onSubmit={(ev) => signIn(ev)}>
                    {/* <img className="mb-4" src={logo} alt={t("Wastewater Treatment Plants Monitoring Database")} width="93" height="93" /> */}
                    <h1 className="h3 mb-3 fw-normal">{t("Log in")}</h1>

                    {errorLoggingIn && <div className="alert alert-danger" role="alert">
                        {t("logInError")}
                    </div>}

                    <div className="form-floating">
                        <input name="username" type="text" className="form-control" placeholder={t("SSW ID")} id="username" required onFocus={() => setErrorLoggingIn(false)} />
                        <label>{t("SSW ID")}</label>
                    </div>
                    <div className="form-floating">
                        <input name="password" type="password" className="form-control" placeholder={t("Password")} id="password" required onFocus={() => setErrorLoggingIn(false)} />
                        <label>{t("Password")}</label>
                    </div>

                    <div className="checkbox mb-3">
                        <label>
                            <input name="rememberme" type="checkbox" defaultValue="true" /> {t("Remember me")}
                        </label>
                    </div>
                    <button className="w-100 btn btn-lg btn-primary" type="submit">{t("Sign in")}</button>
                    {/* <p className="pt-2 text-muted">{t("Contact the system administrator if your don't have an account.")}</p> */}
                    <Link className="text-muted" to={"/forgotpassword"}>{t("Forgot your password? Click here to change password.")}</Link>
                </form>
            </div>
    );
}

export default Login;
