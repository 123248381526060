import { useEffect, useRef, useState } from 'react';
import { Alert, Button, ButtonGroup, Col, Form, InputGroup, Modal, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import { Pencil, Save2, Search } from 'react-bootstrap-icons';
import useFetch from 'use-http';
import DataTable from 'react-data-table-component';
import { Formik } from 'formik';
import * as yup from 'yup';

const Translations: React.FC = (props) => {
    const [translations, setTranslations] = useState<Record<string, any>>({});
    const [filteredTranslations, setFilteredTranslations] = useState<Record<string, any>>({});
    const [selectedTranslation, setSelectedTranslation] = useState<Record<string, any> | null>(null)
    const [loadingError, setLoadingError] = useState(false);
    const [isFiltering, setIsFiltering] = useState(false);
    const [search, setSearch] = useState('');
    const [editTranslationModalShow, setEditTranslationModalShow] = useState(false);
    const editFormRef = useRef<any>(null);
    const availableLocales = ["en", "el"];
    const [isLoading, setIsLoading] = useState(false);

    const { get, post, put, del, response, loading, error, cache } = useFetch('/api/translations');
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    const handleSearchText = (event: any) => {
        setSearch(event.target.value);
    };

    const handleSearch = () => {
        if (search) {
            const s = search.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "");
            const filtered = Object.entries(translations).filter(x => `${x[0]} ${x[1].en} ${x[1].el}`.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "").includes(s));
            const f: any = {};
            filtered.forEach((x) => {
                f[x[0]] = x[1];
            })
            setFilteredTranslations(f);
            setIsFiltering(true);
        } else {
            setIsFiltering(false);
        }
    };

    useEffect(() => {
        handleSearch();
    }, [translations])

    useEffect(() => {
        getTranslations();
    }, [])

    useEffect(() => {
        setIsLoading(loading);
    }, [loading])

    const getTranslations = async () => {
        setLoadingError(false);
        const ts: Record<string, any> = {};
        for (const locale of availableLocales) {
            const t: Record<string, any> = await get(locale);
            if (response.ok) {
                Object.keys(t).forEach((key) => {
                    ts[key] = { ...ts[key], [locale]: t[key] };
                });
            }
            if (response.redirected) {
                navigate("/login");
            }
            if (error || response.status >= 300) {
                setLoadingError(true);
                return;
            }
        }

        setTranslations(ts);
    }

    const validationSchema = yup.object().shape({
        key: yup.string().required(t("wtp.validation.required")).typeError(t("wtp.validation.type_error")),
        en: yup.string().required(t("wtp.validation.required")).typeError(t("wtp.validation.type_error")),
        el: yup.string().required(t("wtp.validation.required")).typeError(t("wtp.validation.type_error")),
    });

    const paginationComponentOptions = {
        rowsPerPageText: t("rows_per_page"),
        rangeSeparatorText: t("rows_per_page_of"),
        selectAllRowsItem: true,
        selectAllRowsItemText: i18n.language === "en" ? "All" : "Όλες"
    };

    const customStyles = {
        headCells: {
            style: {
                fontSize: '16px',
                fontWeight: 'bold',
            },
        },
        cells: {
            style: {
                fontSize: '16px',
            },
        },
    };

    const columns = [
        {
            cell: (row: any[]) => (
                <ButtonGroup>
                    <Button
                        size="sm"
                        variant="outline-primary"
                        style={{ border: "none" }}
                        className="d-flex align-items-center justify-content-center m-0"
                        onClick={() => {
                            setSelectedTranslation(row);
                            setEditTranslationModalShow(true);
                        }}
                    >
                        <Pencil />
                    </Button>
                </ButtonGroup>
            ),
            width: "80px"
        },
        {
            name: t("admin.translations.key"),
            selector: (row: any[]) => row[0],
            omit: true,
        },
        {
            name: t("admin.translations.en"),
            width: "50%",
            selector: (row: any[]) => row[1].en || row[0],
            conditionalCellStyles: [{
                when: (row: any[]) => row[1].enChanged,
                style: { fontWeight: 'bold' },
            }]
        },
        {
            name: t("admin.translations.el"),
            width: "50%",
            selector: (row: any[]) => row[1].el || "",
            conditionalCellStyles: [{
                when: (row: any[]) => row[1].elChanged,
                style: { fontWeight: 'bold' },
            }]
        },
    ];

    const handleEditSubmit = async (val: { key?: string, en?: string, el?: string }) => {
        if (val.key === undefined || val.en === undefined || val.el === undefined) {
            setEditTranslationModalShow(false);
            setSelectedTranslation(null);
            return;
        }
        if (translations[val.key].en !== val.en) {
            translations[val.key].en = val.en;
            translations[val.key].enChanged = true;
        }
        if (translations[val.key].el !== val.el) {
            translations[val.key].el = val.el;
            translations[val.key].elChanged = true;
        }

        setEditTranslationModalShow(false);
        setSelectedTranslation(null);
    };

    const saveChanges = async () => {
        setIsLoading(true);
        if (Object.values(translations).some(x => x.enChanged)) {
            const en: any = {};
            Object.keys(translations).forEach(x => en[x] = translations[x].en);
            await post("/en", JSON.stringify(en, null, 4));
            if (response.ok) {
                cache.delete(`url:/api/translations/en||method:GET||body:`);
            }
            if (response.redirected) {
                navigate("/login");
            }
            if (error || response.status >= 300) {
                setEditTranslationModalShow(false);
                setSelectedTranslation(null);
                setIsLoading(false);
                return;
            }
        }

        if (Object.values(translations).some(x => x.elChanged)) {
            const el: any = {};
            Object.keys(translations).forEach(x => el[x] = translations[x].el);
            await post("/el", JSON.stringify(el, null, 4));
            if (response.ok) {
                cache.delete(`url:/api/translations/el||method:GET||body:`);
            }
            if (response.redirected) {
                navigate("/login");
            }
            if (error || response.status >= 300) {
                setEditTranslationModalShow(false);
                setSelectedTranslation(null);
                setIsLoading(false);
                return;
            }
        }

        await getTranslations();
        setIsLoading(true);
    }

    return (
        <>
            {!loading && !error && translations && <>
                <Row>
                    <Col>
                        <h4>{t("admin.translations")}</h4>
                    </Col>
                    <Col>
                        <ButtonGroup className='float-end btn-group-sm mt-2' aria-label="Toolbar">
                            <Button variant="outline-primary" className="d-flex align-items-center justify-content-center m-0" onClick={saveChanges} disabled={loading}><Save2 className="me-2"></Save2> {t("wtp.button.save")}</Button>
                        </ButtonGroup>
                        <InputGroup
                            className="mt-2"
                            size="sm"
                            style={{ width: "350px" }}
                        >
                            <Form.Control
                                id="search"
                                name="search"
                                placeholder={t("search_for")}
                                onChange={handleSearchText}
                                onKeyDown={(event) => {
                                    if (event.key === "Enter") {
                                        handleSearch();
                                    }
                                }}
                                value={search || ""}
                            />
                            <Button variant="outline-secondary" id="button-addon2" onClick={handleSearch}>
                                <Search />
                            </Button>
                        </InputGroup>
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col>
                        <DataTable
                            columns={columns}
                            data={isFiltering ? Object.entries(filteredTranslations) : Object.entries(translations)}
                            pagination
                            // paginationComponent={BootyPagination}
                            customStyles={customStyles}
                            noDataComponent={<div className="text-center">
                                {t("wtp.table.no_data")}
                            </div>}
                            paginationComponentOptions={paginationComponentOptions}
                            responsive={false}
                            className='translations-table'
                        />
                    </Col>
                </Row>
            </>}
            {!loading && loadingError && <Alert variant="danger">
                <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
                <pre>{JSON.stringify(error, null, 4)}</pre>
            </Alert>}
            {isLoading && <div className="d-flex justify-content-center w-100" style={{ minHeight: "calc(100vh - 294px)" }}>
                <div className="spinner-border" style={{ width: "10rem", height: "10rem", marginTop: "200px" }} role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>}
            <Modal
                show={editTranslationModalShow}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                onHide={() => setEditTranslationModalShow(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {`${t("wtp.button.edit_capitalized")} ${t("admin.parameter")}`}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Formik
                        initialValues={selectedTranslation ? { key: selectedTranslation[0], en: selectedTranslation[1].en, el: selectedTranslation[1].el } : {}}
                        validationSchema={validationSchema}
                        onSubmit={(v) => handleEditSubmit(v)}
                    >
                        {props => <Form noValidate ref={editFormRef} onSubmit={props.handleSubmit}>
                            <Form.Control type='hidden' name="id" id="id" value={selectedTranslation?.id} />
                            <Form.Group as={Row} className="mb-2">
                                <Form.Label column className="text-end" md={{ span: 4 }}>{t("admin.translations.key")}</Form.Label>
                                <Col md={{ span: 8 }}>
                                    <Form.Control
                                        id="key"
                                        name="key"
                                        value={props.values.key}
                                        onChange={props.handleChange}
                                        isInvalid={(props.touched as any).key && !!(props.errors as any).key}
                                        disabled={true}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {(props.errors as any).key}
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-2">
                                <Form.Label column className="text-end" md={{ span: 4 }}>{t("admin.translations.en")}</Form.Label>
                                <Col md={{ span: 8 }}>
                                    <Form.Control
                                        id="en"
                                        name="en"
                                        value={props.values.en}
                                        onChange={props.handleChange}
                                        isInvalid={(props.touched as any).en && !!(props.errors as any).en}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {(props.errors as any).en}
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-2">
                                <Form.Label column className="text-end" md={{ span: 4 }}>{t("admin.translations.el")}</Form.Label>
                                <Col md={{ span: 8 }}>
                                    <Form.Control
                                        id="el"
                                        name="el"
                                        value={props.values.el}
                                        onChange={props.handleChange}
                                        isInvalid={(props.touched as any).el && !!(props.errors as any).el}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {(props.errors as any).el}
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                        </Form>}
                    </Formik>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => editFormRef.current?.dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }))}>{t("wtp.button.update")}</Button>
                    <Button variant="secondary" onClick={() => setEditTranslationModalShow(false)}>{t("wtp.button.cancel")}</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default Translations;
