import { useContext, useEffect, useRef, useState } from 'react';
import { Alert, Badge, Button, ButtonGroup, Col, Container, Dropdown, FloatingLabel, Form, InputGroup, ListGroup, Modal, OverlayTrigger, Row, Spinner, Tab, Table, Tabs, Tooltip } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../../../state/State';
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import useFetch from 'use-http';
import { SamplingPointType, WastewaterTreatementPlantReporter, WastewaterTreatementPlantUser, WastewaterTreatmentPlantType } from '../../../data/Definitions';
import { PlusCircle, PencilFill, Trash, Pencil, Eye, Search, PersonCheckFill, PersonXFill, FiletypeXlsx } from 'react-bootstrap-icons';
import { Formik } from 'formik';
import * as yup from 'yup';
import Select from 'react-select';
import { ConfirmationPopover } from '../../../components/ConfirmationPopover';
import DataTable from 'react-data-table-component';

const WastewaterTreatmentPlantsIndex: React.FC = (props) => {
    const [wastewaterTreatmentPlants, setWastewaterTreatmentPlants] = useState<WastewaterTreatementPlantReporter[]>();
    const [users, setUsers] = useState<WastewaterTreatementPlantUser[]>([]);
    const [loadingError, setLoadingError] = useState(false);
    const [addModalShow, setAddModalShow] = useState(false);
    const addFormRef = useRef<any>(null);
    const [editModalShow, setEditModalShow] = useState(false);
    const editFormRef = useRef<any>(null);
    const [selectedWastewaterTreatmentPlant, setSelectedWastewaterTreatmentPlant] = useState<WastewaterTreatementPlantReporter>();
    const [filteredWastewaterTreatmentPlants, setFilteredWastewaterTreatmentPlants] = useState<WastewaterTreatementPlantReporter[]>([]);
    const [isFiltering, setIsFiltering] = useState(false);
    const [search, setSearch] = useState('');

    const { get, put, del, response, loading, error, cache } = useFetch('/api');
    const loc = useLocation();
    const context = useContext(AppContext);
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    const handleSearchText = (event: any) => {
        setSearch(event.target.value);
    };

    const handleSearch = () => {
        if (search) {
            let s = search.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "");
            var filtered = [...(wastewaterTreatmentPlants ?? [])].filter(x => `${x.code} ${x.name} ${x.nameEn} ${t(WastewaterTreatmentPlantType[x.type])}`.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "").includes(s));
            setFilteredWastewaterTreatmentPlants(filtered);
            setIsFiltering(true);
        } else {
            setIsFiltering(false);
        }
    };

    useEffect(() => {
        context.dispatch({ type: "SetMainTitleAction", title: "Wastewater Treatment Plants" });
    }, [loc.pathname]);

    useEffect(() => {
        getWastewaterTreatmentPlants();
    }, [])

    const getWastewaterTreatmentPlants = async () => {
        setLoadingError(false);
        let us = await get('/account/users');
        if (response.ok) setUsers(us);
        if (response.redirected) navigate("/login");
        if (error || response.status >= 300) {
            setLoadingError(true);
            return;
        }

        let wtps = await get('/wastewatertreatmentplant');
        if (response.ok) setWastewaterTreatmentPlants(wtps);
        if (response.redirected) navigate("/login");
        if (error || response.status >= 300) {
            setLoadingError(true);
            return;
        }
    }

    const removeWTP = async (id: number) => {
        if (!id) return;
        await del(`/wastewatertreatmentplant/${id}`);
        if (response.ok) {
            cache.delete(`url:/api/wastewatertreatmentplant||method:GET||body:`);
            await getWastewaterTreatmentPlants();
        }
        if (response.redirected) navigate("/login");
        if (error || response.status >= 300) {
            setLoadingError(true);
            return;
        }
    }

    const addNew = async (values: {
        name: string,
        nameEn: string,
        code: string,
        userId: string,
        isPublic: string,
        samplingPointTypes: SamplingPointType[],
    }) => {
        if (!values) return;
        let data = new FormData();
        Object.entries(values).forEach(entry => {
            let [key, value] = entry;
            if (!value) return;
            if (key.startsWith('samplerPositionName')) return;
            if (key.startsWith('samplingPointTypes')) return;
            if (typeof value === 'object') {
                if (Array.isArray(value)) {
                    value.forEach((obj: Object, index: number) => {
                        Object.entries(obj).forEach(innerEntry => {
                            let [innerKey, innerValue] = innerEntry;
                            if (innerValue === null) return;
                            data.append(`${key}[][${index}][${innerKey}]`, innerValue);
                        });
                    });
                } else {
                    data.append(key, JSON.stringify(value));
                }
            } else {
                data.append(key, value);
            }
        });

        data.append('samplingPointTypes[]', "");

        var res = await fetch('/api/wastewatertreatmentplant', {
            method: 'POST',
            body: data,
        });
        if (res.status === 200) {
            cache.delete(`url:/api/wastewatertreatmentplant||method:GET||body:`);
            let id = await res.text();
            navigate(`/wastewatertreatmentplants/view/${id}`)
        } else {
            if (res.redirected) navigate("/login");
        }
    }

    const edit = async (values: {
        name: string,
        nameEn: string,
        code: string,
        userId: string,
        isPublic: string,
        samplingPointTypes: SamplingPointType[],
    }) => {
        if (!values) return;
        if (!selectedWastewaterTreatmentPlant) return;
        let data = new FormData();
        Object.entries(values).forEach(entry => {
            let [key, value] = entry;
            if (!value) return;
            if (key.startsWith('samplerPositionName')) return;
            if (typeof value === 'object') {
                if (Array.isArray(value)) {
                    value.forEach((obj: Object, index: number) => {
                        Object.entries(obj).forEach(innerEntry => {
                            let [innerKey, innerValue] = innerEntry;
                            if (innerValue === null) return;
                            data.append(`${key}[][${index}][${innerKey}]`, innerValue);
                        });
                    });
                } else {
                    data.append(key, JSON.stringify(value));
                }
            } else {
                data.append(key, value);
            }
        });

        var res = await fetch(`/api/wastewatertreatmentplant/${selectedWastewaterTreatmentPlant.id}`, {
            method: 'PUT',
            body: data,
        });
        if (res.status === 200) {
            cache.delete(`url:/api/wastewatertreatmentplant||method:GET||body:`);
            cache.delete(`url:/api/wastewatertreatmentplant/${selectedWastewaterTreatmentPlant.id}||method:GET||body:`);
            cache.delete(`url:/api/wastewatertreatmentplant/samplingpointtypes/${selectedWastewaterTreatmentPlant.id}||method:GET||body:`);
            setEditModalShow(false);
            await getWastewaterTreatmentPlants();
        } else {
            if (res.redirected) navigate("/login");
        }
    }

    const paginationComponentOptions = {
        rowsPerPageText: t("rows_per_page"),
        rangeSeparatorText: t("rows_per_page_of"),
        selectAllRowsItem: true,
        selectAllRowsItemText: i18n.language === "en" ? "All" : "Όλες"
    };

    const customStyles = {
        headCells: {
            style: {
                fontSize: '16px',
                fontWeight: 'bold',
            },
        },
        cells: {
            style: {
                fontSize: '16px',
            },
        },
    };

    const columns = [
        {
            cell: (row: WastewaterTreatementPlantReporter) => (
                <ButtonGroup>
                    <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={<Tooltip>
                            {t("wastewatertreatmentplants.exports.measurements")}
                        </Tooltip>}
                    >
                        <Button
                            href={`/api/reports/measurements/${row.code}`}
                            target="_blank"
                            size="sm"
                            variant="outline-secondary"
                            style={{ border: "none" }}
                        >
                            <FiletypeXlsx />
                        </Button>
                    </OverlayTrigger>
                    <ConfirmationPopover
                        id={`wastewater_treatment_plant-confirmation_popover-${row.id}`}
                        header={t("wtp.reuse.confirmation.header")}
                        body={t("wtp.reuse.confirmation.body")}
                        okLabel={t("wtp.reuse.confirmation.ok")}
                        onOk={() => { removeWTP(row.id) }}
                        cancelLabel={t("wtp.reuse.confirmation.cancel")}
                        onCancel={() => { }}
                    />
                    <Button
                        size="sm"
                        variant="outline-primary"
                        style={{ border: "none" }}
                        className="d-flex align-items-center justify-content-center m-0"
                        onClick={() => { setSelectedWastewaterTreatmentPlant(row); setEditModalShow(true) }}
                    >
                        <Pencil />
                    </Button>
                </ButtonGroup>
            ),
            width: "120px"
        },
        {
            name: t("wastewatertreatmentplants.wastewatertreatmentplant_code"),
            selector: (row: WastewaterTreatementPlantReporter) => row.code,
            sortable: true
        },
        {
            name: t(i18n.language === "en" ? "wastewatertreatmentplants.wastewatertreatmentplant_name_english" : "wastewatertreatmentplants.wastewatertreatmentplant_name"),
            selector: (row: WastewaterTreatementPlantReporter) => (i18n.language === "en" ? row.nameEn : row.name) ?? "",
            sortable: true,
            wrap: true,
        },
        {
            name: t("wastewatertreatmentplants.type"),
            selector: (row: WastewaterTreatementPlantReporter) => t(WastewaterTreatmentPlantType[row.type]) ?? "",
            sortable: true,
            center: true,
            wrap: true,
            style: { textAlign: "center" },
        },
        {
            name: t("wastewatertreatmentplants.operator"),
            selector: (row: WastewaterTreatementPlantReporter) => (() => { let usr = users.find(y => y.id === row.userId); return usr?.userName; })() ?? "",
            sortable: true,
            center: true,
        },
        {
            name: t("wastewatertreatmentplants.operator.email"),
            selector: (row: WastewaterTreatementPlantReporter) => (() => { let usr = users.find(y => y.id === row.userId); return usr?.email; })() ?? "",
            sortable: true,
            center: true,
            wrap: true,
            style: { textAlign: "center" },
        },
        {
            name: t("wastewatertreatmentplants.operator.name"),
            selector: (row: WastewaterTreatementPlantReporter) => (() => { let usr = users.find(y => y.id === row.userId); return usr?.fullName; })() ?? "",
            sortable: true,
            center: true,
            wrap: true,
            style: { textAlign: "center" },
        },
        {
            name: t("wastewatertreatmentplants.operator.last_login_on"),
            selector: (row: WastewaterTreatementPlantReporter) => (() => { let usr = users.find(y => y.id === row.userId); return (usr && usr.lastLoginOn) ? new Date(usr.lastLoginOn).toLocaleString("en-gb") : "-"; })() ?? "",
            sortable: true,
            center: true,
        },
        {
            name: t("wtp.files.additional_files.public"),
            cell: (row: WastewaterTreatementPlantReporter) => (row.isPublic === "1" && row.type === WastewaterTreatmentPlantType.Urban) ? <PersonCheckFill color='green' /> : <PersonXFill color='red' />,
            center: true,
            sortable: false
        }
    ];

    return (
        <Container fluid className='menu-padding'>
            {!loading && !error && wastewaterTreatmentPlants && <>
                <Row>
                    <Col>
                        <h4>{t("Wastewater Treatment Plants")}</h4>
                    </Col>
                    <Col>
                        <ButtonGroup className='float-end btn-group-sm mt-2' aria-label="Toolbar">
                            <Button variant="outline-primary" className="d-flex align-items-center justify-content-center m-0" onClick={() => setAddModalShow(true)}><PlusCircle className="me-2"></PlusCircle> {t("wtp.button.add_upper")}</Button>
                            <Dropdown>
                                <Dropdown.Toggle variant="outline-secondary" style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}>
                                    <FiletypeXlsx className="me-2"></FiletypeXlsx> Exports
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item href="/api/reports/full" target="_blank">{t("wastewatertreatmentplants.exports.full")}</Dropdown.Item>
                                    <Dropdown.Item href="/api/reports/compliance" target="_blank">{t("wastewatertreatmentplants.exports.compliance")}</Dropdown.Item>
                                    <Dropdown.Item href="/api/reports/sludge" target="_blank">{t("wastewatertreatmentplants.exports.sludge")}</Dropdown.Item>
                                    <Dropdown.Item href="/api/reports/reuse" target="_blank">{t("wastewatertreatmentplants.exports.reuse")}</Dropdown.Item>
                                    <Dropdown.Divider />
                                    <Dropdown.Item href="/api/reports/uwwtd" target="_blank">{t("wastewatertreatmentplants.exports.eu")}</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </ButtonGroup>
                        <InputGroup
                            className="mt-2"
                            size="sm"
                            style={{ width: "350px" }}
                        >
                            <Form.Control
                                id="search"
                                name="search"
                                placeholder={t("search_for")}
                                onChange={handleSearchText}
                                onKeyDown={(event) => {
                                    if (event.key === "Enter") {
                                        handleSearch();
                                    }
                                }}
                                value={search || ""}
                            />
                            <Button variant="outline-secondary" id="button-addon2" onClick={handleSearch}>
                                <Search />
                            </Button>
                        </InputGroup>
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col>
                        <DataTable
                            columns={columns}
                            data={isFiltering ? filteredWastewaterTreatmentPlants : wastewaterTreatmentPlants}
                            defaultSortFieldId={2}
                            pagination
                            // paginationComponent={BootyPagination}
                            customStyles={customStyles}
                            noDataComponent={<div className="text-center">
                                {t("wtp.table.no_data")}
                            </div>}
                            paginationComponentOptions={paginationComponentOptions}
                        />
                        {/* <Table striped size="sm">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>{t("wastewatertreatmentplants.wastewatertreatmentplant_code")}</th>
                                    <th>{t(i18n.language === "en" ? "wastewatertreatmentplants.wastewatertreatmentplant_name_english" : "wastewatertreatmentplants.wastewatertreatmentplant_name")}</th>
                                    <th>{t("wastewatertreatmentplants.type")}</th>
                                    <th>{t("wastewatertreatmentplants.operator")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {wastewaterTreatmentPlants.length === 0 ?
                                    <tr>
                                        <td colSpan={4} className="text-center">
                                            {t("wtp.table.no_data")}
                                        </td>
                                    </tr> :
                                    wastewaterTreatmentPlants.map((x: WastewaterTreatementPlantReporter, i: number) => <tr key={i}>
                                        <td className="text-center">{
                                            <ButtonGroup>
                                                <ConfirmationPopover
                                                    id={`wastewater_treatment_plant-confirmation_popover-${i}`}
                                                    header={t("wtp.reuse.confirmation.header")}
                                                    body={t("wtp.reuse.confirmation.body")}
                                                    okLabel={t("wtp.reuse.confirmation.ok")}
                                                    onOk={() => { removeWTP(x.id) }}
                                                    cancelLabel={t("wtp.reuse.confirmation.cancel")}
                                                    onCancel={() => { }}
                                                />
                                                <Button
                                                    size="sm"
                                                    variant="outline-primary"
                                                    style={{ border: "none" }}
                                                    className="d-flex align-items-center justify-content-center m-0"
                                                    onClick={() => { setSelectedWastewaterTreatmentPlant(x); setEditModalShow(true) }}
                                                >
                                                    <Pencil />
                                                </Button>
                                            </ButtonGroup>
                                        }</td>
                                        <td>{x.code}</td>
                                        <td>{i18n.language === "en" ? x.nameEn : x.name}</td>
                                        <td>{t(WastewaterTreatmentPlantType[x.type])}</td>
                                        <td>{(() => { let usr = users.find(y => y.id === x.userId); return `${usr?.userName} (${usr?.email})`; })() ?? ""}</td>
                                    </tr>)}

                            </tbody>
                        </Table> */}
                    </Col>
                </Row>
            </>}
            {!loading && loadingError && <Alert variant="danger">
                <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
                <pre>{JSON.stringify(error, null, 4)}</pre>
            </Alert>}
            {loading && <div className="d-flex justify-content-center w-100" style={{ minHeight: "calc(100vh - 294px)" }}>
                <div className="spinner-border" style={{ width: "10rem", height: "10rem", marginTop: "200px" }} role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>}
            <Modal
                show={addModalShow}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                onHide={() => setAddModalShow(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {t("wastewatertreatmentplants.add_wtp")}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Formik
                        initialValues={{
                            name: "",
                            nameEn: "",
                            code: "",
                            userId: "",
                            isPublic: "0",
                            type: -1,
                            samplingPointTypes: [] as SamplingPointType[],
                        }}
                        validationSchema={yup.object().shape({
                            name: yup.string().required(t("wtp.validation.required")).typeError(t("wtp.validation.type_error")),
                            nameEn: yup.string().required(t("wtp.validation.required")).typeError(t("wtp.validation.type_error")),
                            code: yup.string().required(t("wtp.validation.required")).typeError(t("wtp.validation.type_error")),
                            userId: yup.string().required(t("wtp.validation.no_select")).oneOf(users.map(x => x.id)).typeError(t("wtp.validation.type_error")),
                            type: yup.number().required(t("wtp.validation.required")).oneOf(Object.values(WastewaterTreatmentPlantType).filter(x => !isNaN(parseInt(x.toString()))).map(x => parseInt(x.toString())), t("wtp.validation.no_select")).typeError(t("wtp.validation.type_error")),
                        })}
                        enableReinitialize
                        onSubmit={addNew}
                    >
                        {props => <Form noValidate ref={addFormRef} onSubmit={props.handleSubmit}>
                            <Form.Group as={Row} className="mb-2">
                                <Form.Label column className="text-end" md={{ span: 5 }}>{t("wastewatertreatmentplants.wastewatertreatmentplant_name")}</Form.Label>
                                <Col md={{ span: 7 }}>
                                    <Form.Control
                                        id="name"
                                        name="name"
                                        onChange={props.handleChange}
                                        onBlur={props.handleBlur}
                                        value={(props.values as any).name || ""}
                                        isInvalid={(props.touched as any).name && !!(props.errors as any).name}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {(props.errors as any).name}
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-2">
                                <Form.Label column className="text-end" md={{ span: 5 }}>{t("wastewatertreatmentplants.wastewatertreatmentplant_name_english")}</Form.Label>
                                <Col md={{ span: 7 }}>
                                    <Form.Control
                                        id="nameEn"
                                        name="nameEn"
                                        onChange={props.handleChange}
                                        onBlur={props.handleBlur}
                                        value={(props.values as any).nameEn || ""}
                                        isInvalid={(props.touched as any).nameEn && !!(props.errors as any).nameEn}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {(props.errors as any).nameEn}
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-2">
                                <Form.Label column className="text-end" md={{ span: 5 }}>{t("wastewatertreatmentplants.wastewatertreatmentplant_code")}</Form.Label>
                                <Col md={{ span: 7 }}>
                                    <Form.Control
                                        id="code"
                                        name="code"
                                        onChange={props.handleChange}
                                        onBlur={props.handleBlur}
                                        value={(props.values as any).code || ""}
                                        isInvalid={(props.touched as any).code && !!(props.errors as any).code}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {(props.errors as any).code}
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-2">
                                <Form.Label column className="text-end" md={{ span: 5 }}>{t("wastewatertreatmentplants.operator")}</Form.Label>
                                <Col md={{ span: 7 }}>
                                    <Select
                                        noOptionsMessage={() => t("wtp.select.no_options")}
                                        options={(users.map(x => { return { value: x.id, label: `${x.userName} (${x.email})`, } }).concat([{ value: "", label: t("wastewatertreatmentplants.select_operator"), }]))}
                                        placeholder={t("wastewatertreatmentplants.select_operator")}
                                        id="userId"
                                        name="userId"
                                        onChange={v => props.setFieldValue("userId", v?.value)}
                                        value={(users.map(x => { return { value: x.id, label: `${x.userName} (${x.email})`, } }).concat([{ value: "", label: t("wastewatertreatmentplants.select_operator"), }])).find(x => x.value === props.values.userId)}
                                        className={'react-select ' + ((props.touched.userId && !!props.errors.userId) ? 'is-invalid' : '')}
                                        classNamePrefix={'react-select'}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {props.errors.userId}
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-2">
                                <Form.Label column className="text-end" md={{ span: 5 }}>{t("wastewatertreatmentplants.type")}</Form.Label>
                                <Col md={{ span: 7 }}>
                                    <Form.Select
                                        id="type"
                                        name="type"
                                        onChange={props.handleChange}
                                        value={props.values.type}
                                        isInvalid={props.touched.type && !!props.errors.type}
                                    >
                                        <option key={"none"} value={-1}>{t("wastewatertreatmentplants.select_type")}</option>
                                        {Object.values(WastewaterTreatmentPlantType).filter(x => !isNaN(parseInt(x.toString()))).map((x, i) => <option key={i} value={parseInt(x.toString())}>{t(WastewaterTreatmentPlantType[parseInt(x.toString())])}</option>)}
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        {props.errors.type}
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-2">
                                <Form.Label column className="text-end" md={{ span: 5 }}>{t("wtp.files.additional_files.public")}</Form.Label>
                                <Col md={{ span: 7 }}>
                                    <Form.Select
                                        id="isPublic"
                                        name="isPublic"
                                        disabled={props.values.type != WastewaterTreatmentPlantType.Urban}
                                        onChange={props.handleChange}
                                        value={props.values.type != WastewaterTreatmentPlantType.Urban ? "0" : props.values.isPublic}
                                        isInvalid={props.touched.isPublic && !!props.errors.isPublic}
                                    >
                                        <option key={"no"} value={"0"}>{t("no")}</option>
                                        <option key={"yes"} value={"1"}>{t("yes")}</option>
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        {props.errors.isPublic}
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                        </Form>}
                    </Formik>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        onClick={() => addFormRef.current?.dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }))}
                        disabled={loading}
                    >
                        {t("wtp.button.add")}
                        <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            className='ms-2'
                            hidden={!loading}
                        />
                    </Button>
                    <Button
                        variant="secondary"
                        onClick={() => setAddModalShow(false)}
                        disabled={loading}
                    >
                        {t("wtp.button.cancel")}
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={editModalShow}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                onHide={() => setEditModalShow(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {t("wastewatertreatmentplants.edit_wtp")}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Formik
                        initialValues={{
                            name: selectedWastewaterTreatmentPlant?.name ?? "",
                            nameEn: selectedWastewaterTreatmentPlant?.nameEn ?? "",
                            code: selectedWastewaterTreatmentPlant?.code ?? "",
                            userId: selectedWastewaterTreatmentPlant?.userId ?? "",
                            isPublic: selectedWastewaterTreatmentPlant?.type === WastewaterTreatmentPlantType.Urban ? selectedWastewaterTreatmentPlant?.isPublic ?? "0" : "0",
                            samplingPointTypes: selectedWastewaterTreatmentPlant?.samplingPointTypes ?? [],
                            isUrban: selectedWastewaterTreatmentPlant?.type === WastewaterTreatmentPlantType.Urban,
                        }}
                        validationSchema={yup.object().shape({
                            name: yup.string().required(t("wtp.validation.required")).typeError(t("wtp.validation.type_error")),
                            nameEn: yup.string().required(t("wtp.validation.required")).typeError(t("wtp.validation.type_error")),
                            code: yup.string().required(t("wtp.validation.required")).typeError(t("wtp.validation.type_error")),
                            userId: yup.string().required(t("wtp.validation.no_select")).oneOf(users.map(x => x.id)).typeError(t("wtp.validation.type_error")),
                        })}
                        enableReinitialize
                        onSubmit={edit}
                    >
                        {props => <Form noValidate ref={editFormRef} onSubmit={props.handleSubmit}>
                            <Form.Group as={Row} className="mb-2">
                                <Form.Label column className="text-end" md={{ span: 5 }}>{t("wastewatertreatmentplants.wastewatertreatmentplant_name")}</Form.Label>
                                <Col md={{ span: 7 }}>
                                    <Form.Control
                                        id="name"
                                        name="name"
                                        onChange={props.handleChange}
                                        onBlur={props.handleBlur}
                                        value={(props.values as any).name || ""}
                                        isInvalid={(props.touched as any).name && !!(props.errors as any).name}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {(props.errors as any).name}
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-2">
                                <Form.Label column className="text-end" md={{ span: 5 }}>{t("wastewatertreatmentplants.wastewatertreatmentplant_name_english")}</Form.Label>
                                <Col md={{ span: 7 }}>
                                    <Form.Control
                                        id="nameEn"
                                        name="nameEn"
                                        onChange={props.handleChange}
                                        onBlur={props.handleBlur}
                                        value={(props.values as any).nameEn || ""}
                                        isInvalid={(props.touched as any).nameEn && !!(props.errors as any).nameEn}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {(props.errors as any).nameEn}
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-2">
                                <Form.Label column className="text-end" md={{ span: 5 }}>{t("wastewatertreatmentplants.wastewatertreatmentplant_code")}</Form.Label>
                                <Col md={{ span: 7 }}>
                                    <Form.Control
                                        id="code"
                                        name="code"
                                        onChange={props.handleChange}
                                        onBlur={props.handleBlur}
                                        value={(props.values as any).code || ""}
                                        isInvalid={(props.touched as any).code && !!(props.errors as any).code}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {(props.errors as any).code}
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-2">
                                <Form.Label column className="text-end" md={{ span: 5 }}>{t("wastewatertreatmentplants.operator")}</Form.Label>
                                <Col md={{ span: 7 }}>
                                    <Select
                                        noOptionsMessage={() => t("wtp.select.no_options")}
                                        options={(users.map(x => { return { value: x.id, label: `${x.userName} (${x.email})`, } }).concat([{ value: "", label: t("wastewatertreatmentplants.select_operator"), }]))}
                                        placeholder={t("wastewatertreatmentplants.select_operator")}
                                        id="userId"
                                        name="userId"
                                        onChange={v => props.setFieldValue("userId", v?.value)}
                                        value={(users.map(x => { return { value: x.id, label: `${x.userName} (${x.email})`, } }).concat([{ value: "", label: t("wastewatertreatmentplants.select_operator"), }])).find(x => x.value === props.values.userId)}
                                        className={'react-select ' + ((props.touched.isPublic && !!props.errors.isPublic) ? 'is-invalid' : '')}
                                        classNamePrefix={'react-select'}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {props.errors.userId}
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-2">
                                <Form.Label column className="text-end" md={{ span: 5 }}>{t("wtp.files.additional_files.public")}</Form.Label>
                                <Col md={{ span: 7 }}>
                                    <Form.Select
                                        id="isPublic"
                                        name="isPublic"
                                        onChange={props.handleChange}
                                        value={props.values.isPublic}
                                        isInvalid={props.touched.isPublic && !!props.errors.isPublic}
                                        disabled={!props.values.isUrban}
                                    >
                                        <option key={"no"} value={"0"}>{t("no")}</option>
                                        <option key={"yes"} value={"1"}>{t("yes")}</option>
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        {props.errors.isPublic}
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                        </Form>}
                    </Formik>
                </Modal.Body>
                <Modal.Footer>
                    <Link to={`/wastewatertreatmentplants/view/${selectedWastewaterTreatmentPlant?.id ?? ""}`}><Button variant="outline-primary">{t("wtp.button.edit_wtp_data")}</Button></Link>
                    <Button
                        variant="success"
                        onClick={() => editFormRef.current?.dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }))}
                        disabled={loading}
                    >
                        {t("wtp.button.save")}
                        <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            className='ms-2'
                            hidden={!loading}
                        />
                    </Button>
                    <Button
                        variant="secondary"
                        onClick={() => setEditModalShow(false)}
                        disabled={loading}
                    >
                        {t("wtp.button.cancel")}
                    </Button>
                </Modal.Footer>
            </Modal>
        </Container >
    );
}

export default WastewaterTreatmentPlantsIndex;
