import './App.css';
import Footer from './components/Footer';
import Header from './components/Header';
import Home from './pages/Home';
import {
    Routes,
    Route,
    useLocation,
    Navigate
} from "react-router-dom";
import WastewaterTreatmentPlants from './pages/WastewaterTreatmentPlants';
import Login from './pages/Login';
import Agglomerations from './pages/Agglomerations';
import AgglomerationsAdd from './pages/Reporter/Agglomerations/Add';
import QueryTools from './pages/QueryTools';
import Announcements from './pages/Announcements';
import AnnouncementView from './pages/AnnouncementView';
import GenericPage from './pages/GenericPage';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AppContext } from './state/State';
import WtpManage from './pages/Operator/WtpManage';
import SettlementsAdd from './pages/Reporter/Settlements/Add';
import SettlementsEdit from './pages/Reporter/Settlements/Edit';
import SettlementsIndex from './pages/Reporter/Settlements/Index';
import AgglomerationsIndex from './pages/Reporter/Agglomerations/Index';
import AgglomerationsEdit from './pages/Reporter/Agglomerations/Edit';
import AgglomerationsView from './pages/Reporter/Agglomerations/View';
import WastewaterTreatmentPlantsIndex from './pages/Reporter/WastewaterTreatmentPlants/Index';
import WastewaterTreatmentPlantsOperatorIndex from './pages/Reporter/WastewaterTreatmentPlants/OperatorIndex';
import NotFound from './pages/NotFound';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import DataIndex from './pages/Admin/Index';
import Snapshots from './pages/Reporter/Snapshots';

const App: React.FC = () => {
    const loc = useLocation();
    const { t, i18n } = useTranslation();
    const context = useContext(AppContext);
    const [ready, setReady] = useState(false);
    const [showMenu, setShowMenu] = useState(false);

    useEffect(() => {
        let mt = t(context.state.mainAppTitle);
        if (mt != "" && mt != null && mt != undefined) {
            mt += " - ";
        }

        document.title = `${mt}${t('appTitle')}`;
    }, [context.state.mainAppTitle, i18n.resolvedLanguage]);

    useEffect(() => {
        checkCookie();
    }, [])

    const checkCookie = async () => {
        let res = await fetch('/api/account/cookie');
        if (res.status === 200 && !res.redirected) {
            let userInfo = await res.json();
            context.dispatch({
                type: "LOGIN",
                payload: {
                    userInfo: userInfo,
                }
            });
        }
        setReady(true);
    }

    return (
        ready ? <>
            <Header showMenu={showMenu} setShowMenu={setShowMenu}></Header>
            <main>
                <Routes>
                    <Route path="/wtp" element={<WastewaterTreatmentPlants />}></Route>
                    <Route path="/login" element={<Login onLogin={() => setShowMenu(true)} />}></Route>
                    <Route path="/agglomerations" element={<Agglomerations />}></Route>
                    <Route path="/queries" element={<QueryTools />}></Route>
                    <Route path="/announcements" element={<Announcements />}></Route>
                    <Route path="/announcements/:slug" element={<AnnouncementView />}></Route>
                    <Route path="/pages/:slug" element={<GenericPage />}></Route>
                    <Route path="/operator/wtp" element={context.state.loggedIn ? <WtpManage /> : <Navigate replace to="/login" />}></Route>
                    <Route path="/wastewatertreatmentplants/index" element={context.state.loggedIn ? context.state.userInfo?.roles.findIndex(x => x.toLowerCase() === "reporter") !== -1 ? <WastewaterTreatmentPlantsIndex /> : context.state.userInfo?.roles.findIndex(x => x.toLowerCase() === "operator") !== -1 ? <WastewaterTreatmentPlantsOperatorIndex /> : <Navigate replace to="/login" /> : <Navigate replace to="/login" />}></Route>
                    <Route path="/wastewatertreatmentplants/view/:id" element={(context.state.loggedIn && (context.state.userInfo?.roles.findIndex(x => x.toLowerCase() === "reporter") !== -1 || context.state.userInfo?.roles.findIndex(x => x.toLowerCase() === "operator") !== -1)) ? <WtpManage /> : <Navigate replace to="/login" />}></Route>
                    <Route path="/settlements/index" element={(context.state.loggedIn && context.state.userInfo?.roles.findIndex(x => x.toLowerCase() === "reporter") !== -1) ? <SettlementsIndex /> : <Navigate replace to="/login" />}></Route>
                    <Route path="/settlements/add" element={(context.state.loggedIn && context.state.userInfo?.roles.findIndex(x => x.toLowerCase() === "reporter") !== -1) ? <SettlementsAdd /> : <Navigate replace to="/login" />}></Route>
                    <Route path="/settlements/edit/:id" element={context.state.loggedIn ? <SettlementsEdit /> : <Navigate replace to="/login" />}></Route>
                    <Route path="/agglomerations/index" element={(context.state.loggedIn && context.state.userInfo?.roles.findIndex(x => x.toLowerCase() === "reporter") !== -1) ? <AgglomerationsIndex /> : <Navigate replace to="/login" />}></Route>
                    <Route path="/agglomerations/add" element={(context.state.loggedIn && context.state.userInfo?.roles.findIndex(x => x.toLowerCase() === "reporter") !== -1) ? <AgglomerationsAdd /> : <Navigate replace to="/login" />}></Route>
                    <Route path="/agglomerations/edit/:id" element={(context.state.loggedIn && context.state.userInfo?.roles.findIndex(x => x.toLowerCase() === "reporter") !== -1) ? <AgglomerationsEdit /> : <Navigate replace to="/login" />}></Route>
                    <Route path="/agglomerations/view/:id" element={context.state.loggedIn ? <AgglomerationsView /> : <Navigate replace to="/login" />}></Route>
                    <Route path="/snapshots" element={context.state.loggedIn && context.state.userInfo?.snapshotInstance && context.state.userInfo?.roles.findIndex(x => x.toLowerCase() === "reporter") !== -1 ? <Snapshots /> : <Navigate replace to="/login" />}></Route>
                    <Route path="/data" element={(context.state.loggedIn && context.state.userInfo?.roles.findIndex(x => x.toLowerCase() === "sysadmin") !== -1) ? <DataIndex /> : <Navigate replace to="/login" />}></Route>
                    <Route path="/" element={<Home />}></Route>
                    <Route path="/ForgotPassword" element={<ForgotPassword />}></Route>
                    <Route path="/ResetPassword" element={<ResetPassword />}></Route>
                    <Route path="/NotFound" element={<NotFound />}></Route>
                </Routes>
            </main>
            <Footer></Footer>
        </> : <div className="d-flex justify-content-center w-100" style={{ position: "absolute", top: "40vh" }}>
            <div className="spinner-border" style={{ width: "10rem", height: "10rem" }} role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
        </div>
    );
}

export default App;
